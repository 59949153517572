import React from "react";
import "./Pagination.scss"

const SimplePagination = (props) => {
    return (
        <div className="pagination_section">
            <button className="pagination_button pagination_button_left" aria-label="Previous Page" onClick={() => props.clickAction('prev')}>
                <svg xmlns="http://www.w3.org/2000/svg" height="10px" width="8px" viewBox="0.084 -19 22.92 38" >
                    <path d="M 18 -19 L 23 -15 L 9 -0 L 23 15 L 18 19 L 0.084 -0.02 Z" />
                </svg>
            </button>
            {props.pageIndex > 1 && (
                 <button className={"pagination_button"} aria-label={"Page " + (props.pageIndex - 1)} onClick={() => props.clickAction(props.pageIndex - 2)}>{props.pageIndex - 1}</button>
            )}
            {props.pageIndex > 0 && (
                 <button className={"pagination_button"} aria-label={"Page " + (props.pageIndex)} onClick={() => props.clickAction(props.pageIndex - 1)}>{props.pageIndex}</button>
            )}
            <button className={"pagination_button pagination_button_active"} aria-label={"Page " + (props.pageIndex + 1) +  ' selected'} onClick={() => props.clickAction(props.pageIndex)}>{props.pageIndex + 1}</button>
            {props.pageIndex < props.pageCount - 1 && ( 
                 <button className={"pagination_button"} aria-label={"Page " + (props.pageIndex + 2)} onClick={() => props.clickAction(props.pageIndex + 1)}>{props.pageIndex + 2}</button>
            )}
            {props.pageIndex < props.pageCount - 2 && (
                 <button className={"pagination_button"} aria-label={"Page " + (props.pageIndex + 3)} onClick={() => props.clickAction(props.pageIndex + 2)}>{props.pageIndex + 3}</button>
            )}
            <button className="pagination_button pagination_button_right" aria-label="Next Page" onClick={() => props.clickAction('next')}>
                <svg xmlns="http://www.w3.org/2000/svg" height="10px" width="8px" viewBox="-23 -19 22.92 38" >
                    <path d="M -18 19 L -23 15 L -9 0 L -23 -15 L -18 -19 L -0.084 0.02 Z" />
                </svg>
            </button> 
        </div>
    );
};

export default SimplePagination;