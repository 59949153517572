export function getApiBaseUrl(){
    return process.env.REACT_APP_API_BASE_URL
}

export function buildForm(obj){
    var formBody = [];
    for (var property in obj) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(obj[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return formBody
}

export function formatDate(date){
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2,'0');
  let day = date.getDate().toString().padStart(2,'0');
  let hours = date.getHours().toString().padStart(2,'0');
  let minutes = date.getMinutes().toString().padStart(2,'0');
  let seconds = date.getSeconds().toString().padStart(2,'0');
        
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export function isEmail(val) {
  let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(!regEmail.test(val)){
    return false;
  }else{
    return true;
  }
}


