import React, { useCallback, useEffect, useState, useMemo } from 'react';
import './schoolSummary.scss';
import SchoolSideBar from '../../components/SchoolSideBar/SchoolSideBar';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import { useParams } from 'react-router-dom';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { useUser } from '../../context/UserContext';
import { buildForm, getApiBaseUrl } from "../../helpers/globalFunctions";
import EditGoalModal from './modals/editGoalModal';
import EditMessageModal from './modals/editMessageModal';

const SchoolSummary = ({setModalContent, displayModal, closeModal, setSchoolName, setShowSchoolName}) => {
    const { event_id } = useParams();
    const { company_id } = useParams();
    const { getUserJwt } = useUser();
    const [amountRaised, setAmountRaised] = useState(0);
    const [goal, setGoal] = useState(0);
    const [amountPercent, setAmountPercent] = useState(0);
    const [numberStudentsEnrolled, setNumberStudentsEnrolled] = useState(0);
    const [coordinatorConsId, setCoordinatorConsId] = useState(0);
    const [numberOnlineStudents, setNumberOnlineStudents] = useState(0);
    const [numberCompletingStudents, setNumberCompletingStudents] = useState(0);
    const [onlinePercent, setOnlinePercent] = useState(0);
    const [completedPercent, setCompletedPercent] = useState(0);
    

    let USDollar = useMemo(() => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }), []);
    const [message, setMessage] = useState('')

    const loadStudentTotals = useCallback (async () => {
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        let jwt = await getUserJwt()
        var bearer = "Bearer " + jwt;
        headers.append("Authorization", bearer);

        fetch(`${getApiBaseUrl()}/school/schoolSummary/${company_id}/${event_id}/`, {
            method: 'get',
            headers: headers,
        })
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                setNumberOnlineStudents(responseJson.data.number_of_online_students_registered);
                setNumberCompletingStudents(responseJson.data.number_of_students_completing_finns_mission);
                setNumberStudentsEnrolled(responseJson.data.number_of_students_enrolled);
                setOnlinePercent(responseJson.data.online_percent);
                setCompletedPercent(responseJson.data.completed_percent);
            })
            .catch((error) => {
                console.error(error)
            });
    }, [company_id, event_id, getUserJwt])

    const loadGoal = useCallback (async () => {
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        let jwt = await getUserJwt()
        var bearer = "Bearer " + jwt;
        headers.append("Authorization", bearer);

        fetch(`${getApiBaseUrl()}/school/getCompanyRaisedAmountAndGoal/${company_id}/${event_id}/`, {
            method: 'get',
            headers: headers,
        })
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                setAmountRaised(USDollar.format(responseJson.amountRaised));
                setGoal(USDollar.format(responseJson.goal));
                if(responseJson.goal > 0)
                {
                    let tempPercent = Math.round(responseJson.amountRaised * 100.00 / responseJson.goal);
                    if (tempPercent > 100)
                        tempPercent = 100;
                    setAmountPercent(tempPercent);
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }, [USDollar, company_id, event_id, getUserJwt])

    useEffect(() => {
        async function fetchData() {
            var headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
            let jwt = await getUserJwt()
            var bearer = "Bearer " + jwt;
            headers.append("Authorization", bearer);

            fetch(`${getApiBaseUrl()}/school/${company_id}/${event_id}/`, {
                method: 'get',
                headers: headers,
            })
                .then((response) => {
                    return response.json()
                })
                .then((responseJson) => {
                    setSchoolName(responseJson.data.schools.school_name);
                    setShowSchoolName(true);
                    setCoordinatorConsId(responseJson.data.schools.company_coordinator_id);
                    setMessage(responseJson.data.schools.custom_message ? responseJson.data.schools.custom_message : "")
                    window.webContent.load = 1;
                })
                .catch((error) => {
                    console.error(error)
                });

            loadGoal()
            console.log('load students')
            loadStudentTotals()
            console.log('after load students')
        }
        fetchData()
    }, [company_id, event_id, getUserJwt, loadGoal, loadStudentTotals, setSchoolName, setShowSchoolName])

    const modalBlur = (event, id) => {
        if(event.relatedTarget){
            if(event.relatedTarget.getAttribute('data-modal') !== 'true'){
                event.preventDefault();
                document.getElementById(id).focus();
            }
        }
    }

    const refocusElement = (id, index) => {
        let element = document.getElementById(id);
        if(index < 10){
            if(element && element !== document.activeElement){
                element.focus();
            }else{
                setTimeout(()=>{refocusElement(id, index+1)}, 50)
            }
        }
    }

    const saveGoal = async (goal) => {
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        let jwt = await getUserJwt()
        var bearer = "Bearer " + jwt;
        headers.append("Authorization", bearer);

        let body_data = {
            event_id: event_id,
            company_id: company_id,
            goal: goal,
        }
        var formBody = buildForm(body_data)
        
        return fetch(`${getApiBaseUrl()}/school/updateCompanyGoal`, {
            method: 'post',
            headers: headers,
            body: formBody,
        }).then((response) => {
            return response.json()
        })
        .then((responseJson) => {
            if(responseJson.success){
                showEditGoalModal(false)
                loadGoal()
            }
        })
        .catch((error) => {
            console.error(error)
        });
    }

    const showEditGoalModal = (show) => {
        if(show){
            setModalContent(<EditGoalModal
                amount={goal}
                modalBlur={modalBlur}
                saveGoal={saveGoal}
                showEditGoalModal={showEditGoalModal}
            />)
            displayModal();
            refocusElement("edit_goal_modal_input",0)
        }else{
            setModalContent("");
            refocusElement("edit_school_goal_button",0)
            closeModal()
        }
    }

    const saveMessage = async () => {
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        let jwt = await getUserJwt()
        var bearer = "Bearer " + jwt;
        headers.append("Authorization", bearer);

        let message = document.getElementById('edit_message_modal_input').value

        let body_data = {
            custom_message: message,
        }

        var formBody = buildForm(body_data)

        return fetch(`${getApiBaseUrl()}/school/${company_id}/${event_id}`, {
            method: 'put',
            headers: headers,
            body: formBody,
        }).then((response) => {
            return response.json()
        })
        .then((responseJson) => {
            if(responseJson.success){
                setMessage(message)
                showEditMessageModal(false)
            }
        })
        .catch((error) => {
            console.error(error)
        });
    }

    const showEditMessageModal = (show) => {
        if(show){
            setModalContent(<EditMessageModal
                message={message}
                modalBlur={modalBlur}
                saveMessage={saveMessage}
                showEditMessageModal={showEditMessageModal}
            />)
            displayModal();
            refocusElement("edit_message_modal_input",0)
        }else{
            setModalContent("");
            refocusElement("edit_school_message_button",0)
            closeModal()
        }
    }

    return (
        <div className="page_container">
            <div className='menu_content'>
                <SchoolSideBar event_id={event_id} company_id={company_id}></SchoolSideBar>
            </div>
            <div className="page_content">
                <h1 className="page_heading">Summary</h1>
                <div className="section_border margin_border">
                    <h2 className="small_heading">School Achievements</h2>
                    <div className='school_achievements'>
                        <div className='school_achievements_circles'>
                            <div className='progress_circle'>
                                <ProgressCircle percentage={onlinePercent} color="#003399"></ProgressCircle>
                            <div className='progress_circle_text'>{numberOnlineStudents} Online students registered of {numberStudentsEnrolled} students enrolled</div>
                            </div>
                            <div className='progress_circle'>
                                <ProgressCircle percentage={completedPercent} color="#003399"></ProgressCircle>
                            <div className='progress_circle_text'>{numberCompletingStudents} Students of {numberOnlineStudents} online students completed Finn's Mission</div>
                            </div>
                        </div>
                        <div className='progress_bar'>
                            <ProgressBar bgcolor="#003399" progress={amountPercent} height={50} />
                            <div className="progress_data">
                                <div className="progress_amounts">
                                    <div className="progress_amounts_text">Raised</div>
                                    <div className="progress_amounts_text"><b>{amountRaised}</b></div>
                                </div>
                                <div className="progress_percent"><b>{amountPercent}%</b></div>
                                <div className="progress_amounts">
                                    <div className="progress_amounts_text">Goal</div>
                                    <div className="progress_amounts_text"><b>{goal}</b></div>
                                </div>
                            </div>
                            <div id="edit_school_goal_button" className='edit_school_goal_button red_text_button'  onClick={(event) => showEditGoalModal(true)}>Edit School Goal</div>
                        </div>
                    </div>
                </div>
                <div className="second_level">
                    <div className="section_qr_code section_border margin_border">
                        <h2 className="qr_code_heading small_heading">School QR Code</h2>
                        <div className="school_qr_code">
                            <div className="qr_code_text">
                                <div className='school_summary_text'>Share your school QR code onto documents, social media, and websites.</div>
                            </div>
                            <div className="qr_code">
                                <div id="nclvs_team_page" client_id="ahayouthmarket" cons_id={coordinatorConsId} event_id={event_id}></div>
                            </div>
                        </div>
                    </div>
                    <div className="section_message section_border margin_border">
                        <h2 className="message_heading small_heading">Message to Your Students</h2>
                        {message === '' && (
                            <>
                                <div className='school_summary_text'>Create an <b>encouraging message</b> for your students in their Headquarters.</div>
                                <button id="edit_school_message_button" className="create_message_button red_button"  onClick={(event) => showEditMessageModal(true)}>Create Message</button>
                            </>
                        )}
                        {message !== '' && (
                            <>
                                <div className='school_summary_text'>Update your <b>student message</b>:</div>
                                <div className='school_summary_text'>"{message}"</div>
                                <button id="edit_school_message_button" className="create_message_button red_button"  onClick={(event) => showEditMessageModal(true)}>Update Message</button>
                            </>
                        )}
                       
                    </div>
                </div>
            </div>
        </div>
    )
    
};

export default SchoolSummary;