import React, { useEffect, useState, useCallback } from 'react';
import './school.scss';
import '../../Theme.scss'
import { useUser } from '../../context/UserContext';
import { buildForm, formatDate, getApiBaseUrl } from '../../helpers/globalFunctions';
import SortTableHeader from '../../components/SortTableHeader/SortTableHeader';
import TablePageSizeOptions from '../../components/TablePageSizeOptions/TablePageSizeOptions';
import { useNavigate } from 'react-router-dom';
import SimplePagination from '../../components/Pagination/SimplePagination';
import SelectColumnsModal from '../../modals/SelectColumnsModal/SelectColumnsModal';
import DownloadingModal from '../../modals/DownloadingModal/DownloadingModal';

const School = ({ displayModal, closeModal, setModalContent, setShowSchoolName }) => {
    const navigate = useNavigate();
    const { setWebSmt, getUserJwt } = useUser()

    const [initialSearch, setInitialSearch] = useState(true)
    const [allowNoResults, setAllowNoResults] = useState(false)
    const [shouldSearch, setShouldSearch] = useState(true)
    const [hasFilter, setHasFilter] = useState(false)

    const [pageIndex, setPageIndex] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [eventYears, setEventYears] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [regions, setRegions] = useState([])

    const [schools, setSchools] = useState([])

    const [selectedEventYear, setSelectedEventYear] = useState('')
    const [selectedEventType, setSelectedEventType] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('All')
    const [selectedState, setSelectedState] = useState('')
    const [selectedSchoolName, setSelectedSchoolName] = useState('')
    const [selectedSchoolId, setSelectedSchoolId] = useState('')
    const [selectedCoordinatorName, setSelectedCoordinatorName] = useState('')
    const [selectedCoordinatorId, setSelectedCoordinatorId] = useState('')
    const [sortBy, setSortBy] = useState('school_name')
    const [displaySortBy, setDisplaySortBy] = useState('school_name')
    const [orderBy, setOrderBy] = useState('ASC')
    const [displayOrderBy, setDisplayOrderBy] = useState('ASC')

    // const [colSchoolChecked, setColSchoolChecked] = useState(true)

    const [allColumns, setAllColumns] = useState({
        school_name: { id: 'school_name', name: 'School Name', display: true },
        coordinator_name: { id: 'coordinator_name', name: 'Coordinator Name', display: true },
        region: { id: 'region', name: 'Region', display: true },
        city: { id: 'city', name: 'City', display: true },
        state: { id: 'state', name: 'State', display: true },
        event_year: { id: 'event_year', name: 'Event Year', display: false },
        event_type: { id: 'event_type', name: 'Event Type', display: false },
        school_id: { id: 'school_id', name: 'School ID', display: false },
        coordinator_id: { id: 'coordinator_id', name: 'Coordinator ID', display: false }
    });

    const [downloadingInProgress, setDownloadingInProgress] = useState(false)
    const [downloadingPageIndex, setDownloadingPageIndex] = useState(null)
    const [csvData, setCsvData] = useState(null)
    const [csvDataHeaders, setCsvDataHeaders] = useState(null)

    let states = ["AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"]

    useEffect(() => {
        setShowSchoolName(false)
        if (!initialSearch) {
            setHasFilter(true)
        }
    }, [selectedEventYear, selectedEventType, selectedRegion, selectedState, selectedSchoolName, 
        selectedSchoolId, selectedCoordinatorName, selectedCoordinatorId, sortBy, orderBy, 
        setShowSchoolName, initialSearch])

    useEffect(() => {
        if(shouldSearch){
            async function fetchData() {
                setShouldSearch(false)
                let body_data = {
                    page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                    page_index: pageIndex,
                    event_year: selectedEventYear,
                    event_type: selectedEventType,
                    region: selectedRegion,
                    state: selectedState,
                    school_name: selectedSchoolName,
                    school_id: selectedSchoolId,
                    coordinator_name: selectedCoordinatorName,
                    coordinator_id: selectedCoordinatorId,
                    sort_by: sortBy,
                    order_by: orderBy,
                }
        
                var formBody = buildForm(body_data)
                var headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                let jwt = await getUserJwt()
                var bearer = "Bearer " + jwt;
                headers.append("Authorization", bearer);

                fetch(`${getApiBaseUrl()}/school/searchAllSchools`, {
                    method: 'post',
                    headers: headers,
                    body: formBody,
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            if (initialSearch) {
                                setEventYears(responseJson.data.event_years)
                                setEventTypes(responseJson.data.event_types)
                                setRegions(responseJson.data.regions)
                                setInitialSearch(false)
                            }
        
                            setSchools(responseJson.data.schools)
                            setPageCount(responseJson.data.pages_count)
                            setAllowNoResults(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    });
            }
            fetchData();
        }
    }, [shouldSearch, getUserJwt, initialSearch, orderBy, pageIndex, pageSize, selectedCoordinatorId, 
        selectedCoordinatorName, selectedEventType, selectedEventYear, selectedRegion, selectedSchoolId, 
        selectedSchoolName, selectedState, sortBy])

    const clickSortColumn = (col) => {
        if (col === sortBy) {
            let updated_order_by = orderBy === 'ASC' ? 'DESC' : 'ASC'
            setOrderBy(updated_order_by, setDisplayOrderBy(updated_order_by, setShouldSearch(true)))
        } else {
            setSortBy(col, setDisplaySortBy(col, setShouldSearch(true)))
        }
    }

    const handleEnterPressed = (event) => {
        if(event.keyCode === 13){
            event.preventDefault();
            event.target.click()
            document.activeElement.blur()
            setTimeout(() => {event.target.focus()},500)
        }
    }

    const modalBlur = (event, id) => {
        if(event.relatedTarget){
            if(event.relatedTarget.getAttribute('data-modal') !== 'true'){
                event.preventDefault();
                document.getElementById(id).focus();
            }
        }
    }

    const refocusElement = useCallback ((id, index) => {
        setTimeout(async () => {
            let element = document.getElementById(id);
            if(index < 10){
                if(element && element !== document.activeElement){
                    element.focus();
                }else{
                setTimeout(()=>{refocusElement(id, index+1)}, 50)
                }
            }
        }, 250);
    }, [])

    const clickSearchButton = (col) => {
        setSortBy(displaySortBy, setOrderBy(displayOrderBy, setShouldSearch(true)))
    }

    const clearSearchFilters = () => {
        setSelectedEventYear(eventYears[0].name)
        setSelectedEventType(eventTypes[0].program_name)
        setSelectedRegion('All')
        setSelectedState("")
        setSelectedSchoolName("")
        setSelectedSchoolId('')
        setSelectedCoordinatorName('')
        setSelectedCoordinatorId('')
        setSortBy('school_name')
        setDisplaySortBy('school_name')
        setOrderBy('ASC')
        setDisplayOrderBy('ASC')
    }

    const clearSearch = async () => {
        await clearSearchFilters()
        setHasFilter(false, setShouldSearch(true))
    }

    const updatePageSize = (size) => {
        setPageSize(size, setPageIndex(0, setShouldSearch(true))  )
    }

    const updatePageIndex = (new_index) => {
        if(new_index === 'next'){
            new_index = pageIndex + 1
        }else if(new_index === 'prev'){
            new_index = pageIndex - 1
        }

        if(new_index >=0 && new_index < pageCount){
            setPageIndex(new_index, setShouldSearch(true))
        }
    }

    const showColumnModal = (display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<SelectColumnsModal
                modalBlur={modalBlur} modalClose={showColumnModal}
                columns={allColumns} updateColumnDisplay={updateColumnDisplay}
            />)
            displayModal()
            refocusElement('select_columns_modal_dialog_close',0)
        } else {
            setModalContent("")
            closeModal()
            refocusElement('school_select_columns_button', 0);
        }
    }

    const updateColumnDisplay = (event, col) => {
        let new_column = allColumns[col]
        new_column.display = !new_column.display

        setAllColumns({
            ...allColumns,
            [col]: new_column
        })
    }

    const manageSchool = (event_id, company_id) => {
        console.log(event_id)
        console.log(company_id)
        setWebSmt(event_id, company_id)
        window.scrollTo(0, 0);
        navigate('/school/summary/' + event_id + '/' + company_id)
    }
    
    const exportToCsvHeaders = useCallback(async () => {
        let headers = [];
        let selected_columns = [];

        Object.keys(allColumns).forEach(col => {
            let element = allColumns[col]
            if(element.display){
                headers.push(element.name)
                selected_columns.push(element.id)
            }
        });

        setCsvData([headers])
        setCsvDataHeaders(selected_columns)
        setDownloadingPageIndex(0)
    }, [allColumns])

    const showDownloadingModal = useCallback((display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<DownloadingModal
                modalBlur={modalBlur} modalClose={showDownloadingModal}
                downloadingInProgress={true}
            />)
            displayModal()
            refocusElement('downloading_modal_dialog_close',0)
            setDownloadingInProgress(true)
            exportToCsvHeaders()
        } else {
            setDownloadingInProgress(false)
            setDownloadingPageIndex(null)
            setCsvData(null)
            setCsvDataHeaders(null)
            setModalContent("")
            closeModal()
            refocusElement('schools_download_button', 0);
        }
    }, [closeModal, displayModal, exportToCsvHeaders, refocusElement, setModalContent])

    const downloadFile = useCallback(({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        showDownloadingModal(false)
    }, [showDownloadingModal])

    const showDownloadingError = useCallback(() => {
        setDownloadingInProgress(false)
        setDownloadingPageIndex(null)
        setCsvData(null)
        setCsvDataHeaders(null)

        setModalContent(<DownloadingModal
            modalBlur={modalBlur} modalClose={showDownloadingModal}
            downloadingInProgress={false}
        />)
    }, [setModalContent, showDownloadingModal])

    useEffect(() => {
        async function fetchData() {
            if(downloadingPageIndex != null && csvData){
                if(downloadingInProgress){
                    let download_page_size = 500;
                    let body_data = {
                        page_size: download_page_size,
                        page_index: downloadingPageIndex,
                        event_type: selectedEventType,
                        event_year: selectedEventYear,
                        region: selectedRegion,
                        state: selectedState,
                        school_name: selectedSchoolName,
                        school_id: selectedSchoolId,
                        coordinator_name: selectedCoordinatorName,
                        coordinator_id: selectedCoordinatorId,
                        sort_by: sortBy,
                        order_by: orderBy,
                    }

                    var formBody = buildForm(body_data)
                    var http_headers = new Headers();
                    http_headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                    let jwt = await getUserJwt()
                    var bearer = "Bearer " + jwt;
                    http_headers.append("Authorization", bearer);
            
                
                    fetch(`${getApiBaseUrl()}/school/searchAllSchools`, {
                        method: 'post',
                        headers: http_headers,
                        body: formBody,
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            let new_data = csvData
                            responseJson.data.schools.forEach(element => {
                                let array = [];
                                csvDataHeaders.forEach(header => {
                                    array.push(element[header])
                                });
                            
                                new_data.push(array)
                            });

                            setCsvData(new_data)

                            if(responseJson.data.schools.length < download_page_size ){
                                let today = new Date();
                                let formattedDate = formatDate(today);
                    
                                downloadFile({
                                    data: [...csvData].join('\n'),
                                    fileName: `schools${formattedDate}.csv`,
                                    fileType: 'text/csv',
                                })
                            }else{
                                setDownloadingPageIndex(downloadingPageIndex + 1)
                            }
                        }else{
                            showDownloadingError()
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        showDownloadingError()
                    });
                }else{
                    showDownloadingError()
                }
            }
        }
        fetchData();
    }, [downloadingPageIndex, csvData, csvDataHeaders, downloadFile, downloadingInProgress, 
        getUserJwt, orderBy, selectedCoordinatorId, selectedCoordinatorName, selectedEventType, 
        selectedEventYear, selectedRegion, selectedSchoolId, selectedSchoolName, selectedState, 
        showDownloadingError, sortBy])

    return (
        <div className="page_container">
            <div className="page_content school_page_content">
                <h1 className="page_heading">Schools</h1>
                <div className="section_border">
                    <div className='search_section_info_text'>Select your search criteria below to find a school.</div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_event_year">*Event Year</label>
                            <select id="schools_search_event_year" value={selectedEventYear} aria-label="Event Year Required" 
                            onChange={e => setSelectedEventYear(e.target.value)}>
                                {
                                    eventYears.map((option, index) => (
                                        <option key={option.name} value={option.name}>{option.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_event_type">*Event Type</label>
                            <select id="schools_search_event_type" value={selectedEventType} aria-label="Event Type Required" 
                            onChange={e => setSelectedEventType(e.target.value)}>
                                {
                                    eventTypes.map((option, index) => (
                                        <option key={option.program_name} value={option.program_name}>{option.program_name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_region">Region</label>
                            <select id="schools_search_region" value={selectedRegion} onChange={e => setSelectedRegion(e.target.value)}>
                                <option key="All" value="All">All</option>
                                {
                                    regions.map((option, index) => (
                                        <option key={option.name} value={option.name}>{option.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_event_year">State</label>
                            <select value={selectedState} onChange={e => setSelectedState(e.target.value)}>
                                <option key="" value="">Select</option>
                                <optgroup label="United States">
                                    {
                                        states.map((option, index) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))
                                    }
                                </optgroup>
                                <optgroup label="US Territories">
                                    <option key="AS" value="AS">AS</option>
                                    <option key="FM" value="FM">FM</option>
                                    <option key="GU" value="GU">GU</option>
                                    <option key="MH" value="MH">MH</option>
                                    <option key="MP" value="MP">MP</option>
                                    <option key="PR" value="PR">PR</option>
                                    <option key="PW" value="PW">PW</option>
                                    <option key="VI" value="VI">VI</option>
                                </optgroup>
                                <optgroup label="US Military">
                                    <option key="AA" value="AA">AA</option>
                                    <option key="AE" value="AE">AE</option>
                                    <option key="AP" value="AP">AP</option>
                                </optgroup>
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_school_name">School Name</label>
                            <input type='text' id="schools_search_school_name" value={selectedSchoolName} onChange={e => setSelectedSchoolName(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_school_id">School ID</label>
                            <input type='number' min="0" step="1" id="schools_search_school_id" value={selectedSchoolId} onChange={e => setSelectedSchoolId(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_coordinator_name">Coordinator Name</label>
                            <input type='text' id="schools_search_coordinator_name" value={selectedCoordinatorName} onChange={e => setSelectedCoordinatorName(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_coordinator_id">Coordinator ID</label>
                            <input type='number' min="0" step="1" id="schools_search_coordinator_id" value={selectedCoordinatorId} onChange={e => setSelectedCoordinatorId(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_sort_by">Sort By</label>
                            <select id="schools_search_sort_by" value={displaySortBy} onChange={e => setDisplaySortBy(e.target.value)}>
                                <option key="school_name" value="school_name" className={allColumns.school_name.display ? "" : "hidden"}>School Name</option>
                                <option key="coordinator_name" value="coordinator_name" className={allColumns.coordinator_name.display ? "" : "hidden"}>Coordinator Name</option>
                                <option key="region" value="region" className={allColumns.region.display ? "" : "hidden"}>Region</option>
                                <option key="city" value="city" className={allColumns.city.display ? "" : "hidden"}>City</option>
                                <option key="state" value="state" className={allColumns.state.display ? "" : "hidden"}>State</option>
                                <option key="event_year" value="event_year" className={allColumns.event_year.display ? "" : "hidden"}>Event Year</option>
                                <option key="event_type" value="event_type" className={allColumns.event_type.display ? "" : "hidden"}>Event Type</option>
                                <option key="school_id" value="school_id" className={allColumns.school_id.display ? "" : "hidden"}>School ID</option>
                                <option key="coordinator_id" value="coordinator_id" className={allColumns.coordinator_id.display ? "" : "hidden"}>Company Coordinator ID</option>
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_order_by">Order By</label>
                            <select id="schools_search_order_by" value={displayOrderBy} onChange={e => setDisplayOrderBy(e.target.value)}>
                                <option key="ASC" value="ASC">Ascending</option>
                                <option key="DESC" value="DESC">Descending</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex_section search_section_row search_section_buttons_row'>
                        <div className="search_section_error_text">*Required search criteria</div>
                        <div className='search_section_search_buttons'>
                            <button className={"blue_text_button " + (hasFilter ? 'displayBlock' : 'displayHidden')} onClick={() => clearSearch()}>Clear Search</button>
                            <button className="red_button" onClick={() => clickSearchButton()}>Search</button>
                        </div>
                    </div>
                </div>
                <div className='flex_section search_section_table_heading_row'>
                    <div className='small_heading search_section_table_heading_label'>School Results</div>
                    <div>
                        <div className='search_section_table_buttons'>
                            <button id="school_select_columns_button" tabIndex="0" data-modal="true" className="red_outline_button" onClick={() => showColumnModal(true)}>Select Columns</button>
                            <button id="schools_download_button" className="red_button" onClick={(event) => showDownloadingModal(true)}>Download</button>
                        </div>

                        <TablePageSizeOptions page_options={[10, 25, 50, 'All']} page_value={pageSize} page_action={updatePageSize} />
                    </div>
                </div>
                <div className="table_container">
                    <table>
                        <thead>
                            <tr>
                                <SortTableHeader column_label="School Name" column_id='school_name' sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!allColumns.school_name.display} />
                                <SortTableHeader column_label="Coordinator Name" column_id='coordinator_name' sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!allColumns.coordinator_name.display} />
                                <SortTableHeader column_label="Region" column_id='region' sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!allColumns.region.display} />
                                <SortTableHeader column_label="City" column_id='city' sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!allColumns.city.display} />
                                <SortTableHeader column_label="State" column_id='state' sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!allColumns.state.display} />
                                <SortTableHeader column_label="Event Year" column_id='event_year' sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!allColumns.event_year.display} />
                                <SortTableHeader column_label="Event Type" column_id='event_type' sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!allColumns.event_type.display} />
                                <SortTableHeader column_label="School ID" column_id='school_id' sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!allColumns.school_id.display} />
                                <SortTableHeader column_label="Coordinator ID" column_id='coordinator_id' sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!allColumns.coordinator_id.display} />
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                schools.length > 0 ?
                                schools.map((sch, index) => (
                                    <tr id={`sch${index}`} key={index}>
                                        <SchoolTableCell name="school_name" row={sch} columns={allColumns}></SchoolTableCell>
                                        <SchoolTableCell name="coordinator_name" row={sch} columns={allColumns}></SchoolTableCell>
                                        <SchoolTableCell name="region" row={sch} columns={allColumns}></SchoolTableCell>
                                        <SchoolTableCell name="city" row={sch} columns={allColumns}></SchoolTableCell>
                                        <SchoolTableCell name="state" row={sch} columns={allColumns}></SchoolTableCell>
                                        <SchoolTableCell name="event_year" row={sch} columns={allColumns}></SchoolTableCell>
                                        <SchoolTableCell name="event_type" row={sch} columns={allColumns}></SchoolTableCell>
                                        <SchoolTableCell name="school_id" row={sch} columns={allColumns}></SchoolTableCell>
                                        <SchoolTableCell name="coordinator_id" row={sch} columns={allColumns}></SchoolTableCell>
                                        <td>
                                            <button className='blue_text_button' aria-label={"Manage " + sch.school_name} onClick={() => manageSchool(sch.event_id, sch.school_id)}>Manage</button>
                                        </td>
                                    </tr>
                                ))
                                : <tr><td className='report_no_results' colSpan={100}>{allowNoResults ? 'No Results Found' : ''} </td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                {
                    pageCount > 1 && <SimplePagination pageCount={pageCount} pageIndex={pageIndex} clickAction={updatePageIndex}></SimplePagination>
                }
            </div>
        </div>
    )
};

function SchoolTableCell(props) {
    if (props.columns[props.name].display) {
        return (
            <td>{props.row[props.name]}</td>
        )
    } else {
        return <></>
    }
}

export default School;