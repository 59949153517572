import React from 'react';
import './login.scss';
import { useUser } from '../../context/UserContext';

const Login = () => {
    const {invalidAccount, samlLogin} = useUser()

    return (
        <div className="login_page">
          <p className="login_page_heading">School Management Tool</p>
          <div className="login_page_content">
            <div className="section_border login_page_details">
            <p className="small_heading">Log into the School Management Tool</p>
            <button onClick={() => samlLogin()} className="red_button login_button">Login</button>
            {invalidAccount ? 
                <div className="login_error">The current account is not valid for this website.  
                        Please click <a href="https://login.microsoftonline.com/common/oauth2/v2.0/logout">here</a> to 
                        log out of your account and try again.</div> 
                    : ''}
            </div>
          </div>
        </div>
      )
};

export default Login;