import '../schoolSummary.scss';
import '../../../modal.scss';

function EditMessageModal(props) {
    return (
        <div className='edit_modal_dialog modal_dialog'>
            <div className="flex-column-reverse">
                <h2 className="manage_badges_heading">Create Message</h2>
            </div>
            <div className="school_edit_modal_input_row flex-row">
                <textarea
                    id="edit_message_modal_input"
                    placeholder=""
                    maxLength={500}
                    defaultValue={props.message}
                    onBlur={(event) => props.modalBlur(event, 'manage_badges_modal_dialog_close')}
                    resize="false"
                />
            </div>
            <div className="flex-row">
                <button id="edit_message_cancel_btn" className='red_outline_button' 
                onClick={() => {props.showEditMessageModal(false)}} 
                data-modal="true"
                aria-label="Cancel Edit Message modal">Cancel</button>
                <button id="edit_message_save_btn" className='modal_save_button red_button' 
                onClick={() => {props.saveMessage()}} 
                data-modal="true"
                onBlur={(event) => props.modalBlur(event, 'edit_message_modal_input')} 
                aria-label="Save Message">Save</button>
            </div>
        </div>
    )
}

export default EditMessageModal;