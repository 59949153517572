import React, { useEffect, useState, useCallback } from 'react';
import './participantBadges.scss';
import '../../Theme.scss'
import { useUser } from '../../context/UserContext';
import { buildForm, formatDate, getApiBaseUrl } from '../../helpers/globalFunctions';
import SortTableHeader from '../../components/SortTableHeader/SortTableHeader';
import TablePageSizeOptions from '../../components/TablePageSizeOptions/TablePageSizeOptions';
import ParticipantSideBar from '../../components/ParticipantSideBar/ParticipantSideBar';
import ParticipantBadgesModal from './participantBadgesModal';
import SimplePagination from '../../components/Pagination/SimplePagination';
import SelectColumnsModal from '../../modals/SelectColumnsModal/SelectColumnsModal';
import DownloadingModal from '../../modals/DownloadingModal/DownloadingModal';


const ParticipantBadges = ({ displayModal, closeModal, setModalContent, setShowSchoolName }) => {
    const { getUserJwt } = useUser()

    const [initialSearch, setInitialSearch] = useState(true)
    const [allowNoResults, setAllowNoResults] = useState(false)
    const [shouldSearch, setShouldSearch] = useState(true)
    const [hasFilter, setHasFilter] = useState(false)

    const [pageIndex, setPageIndex] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [eventYears, setEventYears] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [regions, setRegions] = useState([])

    const [badges, setBadges] = useState([])
    const [includeMysteryGift, setIncludeMysteryGift] = useState(false)

    const [selectedEventYear, setSelectedEventYear] = useState('')
    const [selectedEventType, setSelectedEventType] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('All')
    const [selectedParticipantType, setSelectedParticipantType] = useState('All')
    const [selectedStudentName, setSelectedStudentName] = useState('')
    const [selectedStudentId, setSelectedStudentId] = useState('')
    const [selectedSchoolName, setSelectedSchoolName] = useState('')
    const [selectedCompanyId, setSelectedCompanyId] = useState('')
    const [sortBy, setSortBy] = useState('school_name')
    const [displaySortBy, setDisplaySortBy] = useState('school_name')
    const [orderBy, setOrderBy] = useState('ASC')
    const [displayOrderBy, setDisplayOrderBy] = useState('ASC')

    const [updatingBadge, setUpdatingBadge] = useState(false)
    const [selectedStudent, setSelectedStudent] = useState(null)
   
    const [allColumns, setAllColumns] = useState({
        school_name: { id: 'school_name', name: 'School Name', display: true },
        participant_first_name: { id: 'participant_first_name', name: 'Participant First Name', display: true },
        participant_last_name: { id: 'participant_last_name', name: 'Participant Last Name', display: true },
        completed_mission: { id: 'completed_mission', name: "Finn's Mission Completed", display: true },
        mystery_gift: { id: 'mystery_gift', name: 'Rare Mystery Gift', display: true }, 
        coordinator_first_name: { id: 'coordinator_first_name', name: 'Coordinator First Name', display: true },
        coordinator_last_name: { id: 'coordinator_last_name', name: 'Coordinator Last Name', display: true },
        event_date: { id: 'event_date', name: 'Event Date', display: true },
        participation_type_name: { id: 'participation_type_name', name: 'Participant Type', display: true },
        school_type: { id: 'school_type', name: 'School Type', display: true },
    });

    const [downloadingInProgress, setDownloadingInProgress] = useState(false)
    const [downloadingPageIndex, setDownloadingPageIndex] = useState(null)
    const [csvData, setCsvData] = useState(null)
    const [csvDataHeaders, setCsvDataHeaders] = useState(null)
    
    const refocusElement = useCallback((id, index) => {
        setTimeout(async () => {
            let element = document.getElementById(id);
            if(index < 10){
                if(element && element !== document.activeElement){
                    element.focus();
                }else{
                setTimeout(()=>{refocusElement(id, index+1)}, 50)
                }
            }
        }, 250);
    }, [])

    const manageBadges = async (display, focus_id) => {
        if (display) {
            var headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
            let jwt = await getUserJwt()
            var bearer = "Bearer " + jwt;
            headers.append("Authorization", bearer);
    
            fetch(`${getApiBaseUrl()}/student/missions/`+ selectedStudent.student_id +"/"+selectedStudent.event_id, {
                method: 'get',
                headers: headers,
            })
                .then((response) => {
                    return response.json()
                })
                .then((responseJson) => {
                    let user_badges = responseJson.data.missions
                    if(includeMysteryGift){
                        user_badges.push(
                            {
                                mission_id: 'rare_mystery_gift',
                                earned: responseJson.data.mystery_gift.earned,
                                name: "Rare Mystery Gift",
                                url: responseJson.data.mystery_gift.earned ? process.env.REACT_APP_KHC_RARE_MYSTERY_GIFT_EARNED_URL : process.env.REACT_APP_KHC_RARE_MYSTERY_GIFT_UNEARNED_URL,
                                mystery_gift: true
                            }
                        )
                    }
                    
                    document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
                    setModalContent(<ParticipantBadgesModal
                        modalBlur={modalBlur} user_badges={user_badges} 
                        student={selectedStudent} updateBadge={updateBadge} manageBadges={manageBadges}
                    />)
                    displayModal()

                    focus_id = focus_id ? focus_id : "manage_badges_modal_dialog_close"
                    refocusElement(focus_id, 0);
                    
                })
                .catch((error) => {
                    setShouldSearch(false)
                    console.error(error)
                });
        } else {
            setModalContent("")
            closeModal()
            if(selectedStudent.student_id){
                let id="manage_student_badges_" + selectedStudent.student_id + "_" + selectedStudent.event_id
                setTimeout(() => {
                    refocusElement(id, 0);
                    setSelectedStudent(null)
                }, 450);
            }
        }
    }

    const updateBadge = async (event, badge) => {
        if(updatingBadge){
            return
        }else{
            setUpdatingBadge(true)
        }

        try{
            var method = "POST"
            var url = getApiBaseUrl() + `/student/actions/${selectedStudent.event_id}/${selectedStudent.student_id}/${badge.mission_id}_override`;
        
            if(badge.earned){
                method = "DELETE"
            }

            if(badge.mystery_gift === true){
                if(badge.earned){
                    url = getApiBaseUrl() + `/student/mysterygift/${selectedStudent.event_id}/${selectedStudent.student_id}/`
                }else{
                    method = "POST"
                    url = getApiBaseUrl() + `/student/mysterygift/${selectedStudent.event_id}/${selectedStudent.student_id}/`
                }
            }
                let jwt = await getUserJwt()
        
                var headers = new Headers();
                var bearer = "Bearer " + jwt;
                headers.append("Authorization", bearer);
                var options = {
                    method: method,
                    headers: headers,
                };

                fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    if(data.success){
                        manageBadges(true,  "manage_badges_" + badge.mission_id)
                    }
                    setUpdatingBadge(false)
                })
            setUpdatingBadge(false)
        } catch(e){
            console.error(e)
            setUpdatingBadge(false)
        }
    }

    useEffect(() => {
        if (selectedStudent) {
            manageBadges(true, null)
        }
    }, [selectedStudent])


    useEffect(() => {
        setShowSchoolName(false)
        if (!initialSearch) {
            setHasFilter(true)
        }
    }, [selectedEventYear, selectedEventType, selectedRegion, selectedParticipantType, 
        selectedStudentName, selectedStudentId, selectedSchoolName, selectedCompanyId, 
        sortBy, orderBy, setShowSchoolName, initialSearch])

    useEffect(() => {
        if(shouldSearch){
            async function fetchData() {
                setShouldSearch(false)
                let body_data = {
                    page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                    page_index: pageIndex,
                    event_type: selectedEventType,
                    event_year: selectedEventYear,
                    region: selectedRegion,
                    participant_type: selectedParticipantType,
                    student_name: selectedStudentName,
                    student_id: selectedStudentId,
                    school_name: selectedSchoolName,
                    company_id: selectedCompanyId,
                    sort_by: sortBy,
                    order_by: orderBy,
                }

                var formBody = buildForm(body_data)
                var headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                let jwt = await getUserJwt()
                var bearer = "Bearer " + jwt;
                headers.append("Authorization", bearer);

                fetch(`${getApiBaseUrl()}/student/adminReports/participantBadges`, {
                    method: 'post',
                    headers: headers,
                    body: formBody,
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            if (initialSearch) {
                                setEventYears(responseJson.data.event_years)
                                setEventTypes(responseJson.data.event_types)
                                setRegions(responseJson.data.regions)
                                setInitialSearch(false)
                            }
                            setIncludeMysteryGift(responseJson.data.include_mystery_gift)
                            setBadges(responseJson.data.badges)
                            setPageCount(responseJson.data.pages_count)
                            setAllowNoResults(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    });
            }
            fetchData()
        }
    }, [shouldSearch, getUserJwt, initialSearch, orderBy, pageIndex, pageSize, selectedCompanyId, selectedEventType, 
        selectedEventYear, selectedParticipantType, selectedRegion, selectedSchoolName, selectedStudentId, 
        selectedStudentName, sortBy])

    const clickSortColumn = (col) => {
        if (col === sortBy) {
            let updated_order_by = orderBy === 'ASC' ? 'DESC' : 'ASC'
            setOrderBy(updated_order_by, setDisplayOrderBy(updated_order_by, setShouldSearch(true)))
        } else {
            setSortBy(col, setDisplaySortBy(col, setShouldSearch(true)))
        }
    }

    const handleEnterPressed = (event) => {
        if(event.keyCode === 13){
            event.preventDefault();
            event.target.click()
            document.activeElement.blur()
            setTimeout(() => {event.target.focus()},500)
        }
    }

    const modalBlur = (event, id) => {
        if(event.relatedTarget){
            if(event.relatedTarget.getAttribute('data-modal') !== 'true'){
                event.preventDefault();
                document.getElementById(id).focus();
            }
        }
    }

    const clickSearchButton = (col) => {
        setSortBy(displaySortBy, setOrderBy(displayOrderBy, setShouldSearch(true)))
    }

    const clearSearchFilters = () => {
        setSelectedEventYear(eventYears[0].name)
        setSelectedEventType(eventTypes[0].program_name)
        setSelectedRegion('All')
        setSelectedParticipantType("")
        setSelectedStudentName("")
        setSelectedStudentId('')
        setSelectedSchoolName('')
        setSelectedCompanyId('')
        setSortBy('school_name')
        setDisplaySortBy('school_name')
        setOrderBy('ASC')
        setDisplayOrderBy('ASC')
    }

    const clearSearch = async () => {
        await clearSearchFilters()
        setHasFilter(false, setShouldSearch(true))
    }

    const updatePageSize = (size) => {
        setPageSize(size, setPageIndex(0, setShouldSearch(true))  )
    }

    const updatePageIndex = (new_index) => {
        if(new_index === 'next'){
            new_index = pageIndex + 1
        }else if(new_index === 'prev'){
            new_index = pageIndex - 1
        }

        if(new_index >=0 && new_index < pageCount){
            setPageIndex(new_index, setShouldSearch(true))
        }
    }

    const showColumnModal = (display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<SelectColumnsModal
                modalBlur={modalBlur} modalClose={showColumnModal}
                columns={allColumns} updateColumnDisplay={updateColumnDisplay}
            />)
            displayModal()
            refocusElement('select_columns_modal_dialog_close',0)
        } else {
            setModalContent("")
            closeModal()
            refocusElement('badges_select_columns_button', 0);
        }
    }

    const updateColumnDisplay = (event, col) => {
        let new_column = allColumns[col]
        new_column.display = !new_column.display

        setAllColumns({
            ...allColumns,
            [col]: new_column
        })
    }

    const exportToCsvHeaders = useCallback (async () => {
        let headers = [];
        let selected_columns = [];

        Object.keys(allColumns).forEach(col => {
            let element = allColumns[col]
            if(element.display){
                headers.push(element.name)
                selected_columns.push(element.id)
            }
        });

        setCsvData([headers])
        setCsvDataHeaders(selected_columns)
        setDownloadingPageIndex(0)
    }, [allColumns])


    const showDownloadingModal = useCallback ((display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<DownloadingModal
                modalBlur={modalBlur} modalClose={showDownloadingModal}
                downloadingInProgress={true}
            />)
            displayModal()
            refocusElement('downloading_modal_dialog_close',0)
            setDownloadingInProgress(true)
            exportToCsvHeaders()
        } else {
            setDownloadingInProgress(false)
            setDownloadingPageIndex(null)
            setCsvData(null)
            setCsvDataHeaders(null)
            setModalContent("")
            closeModal()
            refocusElement('participant_badges_download_button', 0);
        }
    }, [closeModal, displayModal, exportToCsvHeaders, refocusElement, setModalContent])

    const downloadFile = useCallback (({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        showDownloadingModal(false)
    }, [showDownloadingModal])

    const escapeCsvField = (field) => {
        if (field === null || field === undefined) {
            return '';
        }
        if (typeof field !== 'string') {
            field = String(field);
        }
        if (field.includes(',') || field.includes('"') || field.includes('\n') || field.includes('\r') || field.startsWith(' ') || field.endsWith(' ')) {
            return `"${field.replace(/"/g, '""')}"`;
        }
        return field;
    }

    const showDownloadingError = useCallback (() => {
        setDownloadingInProgress(false)
        setDownloadingPageIndex(null)
        setCsvData(null)
        setCsvDataHeaders(null)

        setModalContent(<DownloadingModal
            modalBlur={modalBlur} modalClose={showDownloadingModal}
            downloadingInProgress={false}
        />)
    }, [setModalContent, showDownloadingModal])

    useEffect(() => {
        async function fetchData() {
            if(downloadingPageIndex != null && csvData){
                if(downloadingInProgress){
                    let download_page_size = 500;
                    let body_data = {
                        page_size: download_page_size,
                        page_index: downloadingPageIndex,
                        event_type: selectedEventType,
                        event_year: selectedEventYear,
                        region: selectedRegion,
                        participant_type: selectedParticipantType,
                        student_name: selectedStudentName,
                        student_id: selectedStudentId,
                        school_name: selectedSchoolName,
                        company_id: selectedCompanyId,
                        sort_by: sortBy,
                        order_by: orderBy,
                    }

                    var formBody = buildForm(body_data)
                    var http_headers = new Headers();
                    http_headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                    let jwt = await getUserJwt()
                    var bearer = "Bearer " + jwt;
                    http_headers.append("Authorization", bearer);
            
                    fetch(`${getApiBaseUrl()}/student/adminReports/participantBadges`, {
                        method: 'post',
                        headers: http_headers,
                        body: formBody,
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            let new_data = csvData
                            responseJson.data.badges.forEach(element => {
                                let array = [];
                                csvDataHeaders.forEach(header => {
                                    array.push(escapeCsvField(element[header]))
                                });
                            
                                new_data.push(array)
                            });

                            setCsvData(new_data)

                            if(responseJson.data.badges.length < download_page_size ){
                                let today = new Date();
                                let formattedDate = formatDate(today);
                    
                                downloadFile({
                                    data: new_data.map(row => row.join(',')).join('\n'),
                                    fileName: `participantsBadges${formattedDate}.csv`,
                                    fileType: 'text/csv',
                                })
                            }else{
                                setDownloadingPageIndex(downloadingPageIndex + 1)
                            }
                        }else{
                            showDownloadingError()
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        showDownloadingError()
                    });
                }else{
                    showDownloadingError()
                }
            }
        }
        fetchData()
    }, [downloadingPageIndex, csvData, csvDataHeaders, downloadFile, downloadingInProgress, getUserJwt, orderBy, 
        selectedCompanyId, selectedEventType, selectedEventYear, selectedParticipantType, selectedRegion, 
        selectedSchoolName, selectedStudentId, selectedStudentName, showDownloadingError, sortBy])

    return (
        <div className="page_container">
            <div className='menu_content'>
                <ParticipantSideBar></ParticipantSideBar>
            </div>
            <div className="page_content">
                <h1 className="page_heading">Badges</h1>
                <div className="section_border">
                    <div className='search_section_info_text'>Select your search criteria below to find participants to manage badges.</div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="badges_search_event_type">*Event Type</label>
                            <select id="badges_search_event_type" value={selectedEventType} aria-label="Event Type Required" 
                                onChange={e => setSelectedEventType(e.target.value)}>
                                {
                                    eventTypes.map((option, index) => (
                                        <option key={index} value={option.program_name}>{option.program_name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="badges_search_event_year">*Event Year</label>
                            <select id="badges_search_event_year" value={selectedEventYear} aria-label="Event Year Required" 
                                onChange={e => setSelectedEventYear(e.target.value)}>
                                {
                                    eventYears.map((option, index) => (
                                        <option key={index} value={option.name}>{option.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="badges_search_region">*Region</label>
                            <select id="badges_search_region" value={selectedRegion} aria-label="Region Required" 
                            onChange={e => setSelectedRegion(e.target.value)}>
                                <option key="All" value="All">All</option>
                                {
                                    regions.map((option, index) => (
                                        <option key={index} value={option.name}>{option.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="badges_search_participant_type">Participant Type</label>
                            <select id="badges_search_participant_type" value={selectedParticipantType} onChange={e => setSelectedParticipantType(e.target.value)}>
                                <option key="All" value="All">All</option>
                                <option key="Student/Parent" value="Student/Parent">Student/Parent</option>
                                <option key="District/School Employee" value="District/School Employee">District/School Employee</option>
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="badges_search_student_name">Student Name</label>
                            <input type='text' id="badges_search_student_name" value={selectedStudentName} onChange={e => setSelectedStudentName(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="badges_search_student_id">Student Constituent ID</label>
                            <input type='number' min="0" step="1" id="badges_search_student_id" value={selectedStudentId} onChange={e => setSelectedStudentId(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="badges_search_school_name">School Name</label>
                            <input type='text' id="badges_search_school_name" value={selectedSchoolName} onChange={e => setSelectedSchoolName(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="badges_search_company_id">Company ID</label>
                            <input type='number' min="0" step="1" id="badges_search_company_id" value={selectedCompanyId} onChange={e => setSelectedCompanyId(e.target.value)} />
                        </div>
                    </div>
                    <div className='flex_section search_section_row search_section_buttons_row'>
                        <div className="search_section_error_text">*Required search criteria</div>
                        <div className='search_section_search_buttons'>
                            <button className={"blue_text_button " + (hasFilter ? 'displayBlock' : 'displayHidden')} onClick={() => clearSearch()}>Clear Search</button>
                            <button className="red_button" onClick={() => clickSearchButton()}>Search</button>
                        </div>
                    </div>
                </div>
                <div className='flex_section search_section_table_heading_row'>
                    <div className='small_heading search_section_table_heading_label'>Badge Results</div>
                    <div>
                        <div className='search_section_table_buttons'>
                            <button id="badges_select_columns_button" tabIndex="0" data-modal="true" className="red_outline_button" onClick={() => showColumnModal(true)}>Select Columns</button>
                            <button id="participant_badges_download_button" className="red_button" onClick={(event) => showDownloadingModal(true)}>Download</button>
                        </div>

                        <TablePageSizeOptions page_options={[10, 25, 50, 'All']} page_value={pageSize} page_action={updatePageSize} />
                    </div>
                </div>
                <div className="table_container">
                    <table>
                        <thead>
                            <tr>
                            <th>Action</th>
                                {
                                   Object.entries(allColumns).map((col, index) => ( 
                                       <SortTableHeader key={index} column_label={col[1].name} column_id={col[1].id} sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!col[1].display} />
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                badges.length > 0 ?
                                badges.map((student, index) => (
                                    <tr id={`part${index}`} key={index}>
                                        <td>
                                            <button id={"manage_student_badges_" + student.student_id + "_" + student.event_id} 
                                            className='blue_text_button' aria-label={"Manage badges for " + student.participant_first_name + " " + student.participant_last_name} 
                                            onClick={() => setSelectedStudent(student)}>Manage</button>
                                        </td> 
                                        {
                                            Object.entries(allColumns).map((col, index) => ( 
                                                <DataTableCell key={index}  name={col[1].id} row={student} columns={allColumns}></DataTableCell>
                                            ))
                                        }
                                    </tr>
                                ))
                                : <tr><td className='report_no_results' colSpan={100}>{allowNoResults ? 'No Results Found' : ''} </td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                {
                    pageCount > 1 && <SimplePagination pageCount={pageCount} pageIndex={pageIndex} clickAction={updatePageIndex}></SimplePagination>
                }
            </div>
        </div>
    )
};

function DataTableCell(props) {
    if (props.columns[props.name].display) {
        return (
            <td>{props.row[props.name]}</td>
        )
    } else {
        return <></>
    }
}

export default ParticipantBadges;