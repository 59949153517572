import './SortTableHeader.scss';

function SortTableHeader(props) {
    let is_active_column = props.column_id === props.sort_by

    let label = `sort by ${props.column_label}`;
    if(is_active_column){
        if(props.order_by === 'ASC'){
            label = `sorting by ${props.column_label} ascending`
        }else{
            label = `sorting by ${props.column_label} descending`
        }
    }

    let id = `sortable_table_header_${props.column_id}`

    if(props.hidden){
        return <></>
    }else{
        return ( 
            <th>
                <div id={id} tabIndex="0" aria-label={label} role='button' onKeyDown={(event) => props.enterAction(event)} className="table_column_header_button" onClick={() => props.action(props.column_id)}>
                    <div>{props.column_label}</div>
                    <div className="table_sort_section">
                        <div aria-hidden="true"  className={"table_sort_asc " + (is_active_column && props.order_by === 'ASC' ? 'table_sort_active': "")}>
                            <svg xmlns="http://www.w3.org/2000/svg"  width="10" height="10" fill="currentColor" viewBox="0 0 16 16" >
                                <path  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                        </div>
                        <div  aria-hidden="true" className={"table_sort_desc " + (is_active_column && props.order_by === 'DESC' ? 'table_sort_active': "")}>
                            <svg xmlns="http://www.w3.org/2000/svg"  width="10" height="10"  fill="currentColor" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </th>
        )
    }
  }

  export default SortTableHeader;