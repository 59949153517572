import {useEffect } from 'react';
import { buildForm, getApiBaseUrl } from '../../helpers/globalFunctions';
import { useUser } from '../../context/UserContext';

const Logout = () => {
    const { user } = useUser();

    useEffect(() => {
        handleLogout()
    });

    const handleLogout = () => {
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')

        var formBody = buildForm({"jwt": user?.jwt})

        return fetch(`${getApiBaseUrl()}/saml/logout`, {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: headers,
            body: formBody,
        })
        .then((res) => {
            window.location.reload(false);
        })
        .catch((error) => {
            console.error(error)
        });
    }
};
export default Logout;