import { useUser } from "../context/UserContext"

const useValidateUser = async () => {

const {appReady, getUserJwt} = useUser()
    if(!appReady){
        getUserJwt()
    }
}

export default useValidateUser