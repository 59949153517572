import React, { useEffect, useState, useCallback } from 'react';
import './giveBackUsGames.scss';
import GiveBackSideBar from '../../components/GiveBackSideBar/GiveBackSideBar';
import { useUser } from '../../context/UserContext';
import { buildForm, formatDate, getApiBaseUrl } from '../../helpers/globalFunctions';
import SortTableHeader from '../../components/SortTableHeader/SortTableHeader';
import TablePageSizeOptions from '../../components/TablePageSizeOptions/TablePageSizeOptions';
import SimplePagination from '../../components/Pagination/SimplePagination';
import SelectColumnsModal from '../../modals/SelectColumnsModal/SelectColumnsModal';
import DownloadingModal from '../../modals/DownloadingModal/DownloadingModal';
import StandardModal from '../../modals/StandardModal/StandardModal';

const GiveBackUsGames = ({ displayModal, closeModal, setModalContent, setShowSchoolName }) => {
    const { getUserJwt } = useUser()
    const [initialSearch, setInitialSearch] = useState(true)
    const [allowNoResults, setAllowNoResults] = useState(false)
    
    const [hasFilter, setHasFilter] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [records, setRecords] = useState([])

    const [selectedDynamicsId, setSelectedDynamicsId] = useState('')
    const [selectedOrderId, setSelectedOrderId] = useState('')
    const [selectedSchoolName, setSelectedSchoolName] = useState('')
    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedEndDate, setSelectedEndDate] = useState('')
    const [sortBy, setSortBy] = useState('first_name')
    const [displaySortBy, setDisplaySortBy] = useState('first_name')
    const [orderBy, setOrderBy] = useState('ASC')
    const [displayOrderBy, setDisplayOrderBy] = useState('ASC')

    const [allColumns, setAllColumns] = useState({
        dynamics_id: { id: 'dynamics_id', name: 'Dynamics ID', display: true },
        order_date: { id: 'order_date', name: 'Order Date', display: true },
        status: { id: 'status', name: 'Status', display: true },
        item_number: { id: 'item_number', name: 'Item #', display: true },
        gift_cert_number: { id: 'gift_cert_number', name: 'Gift Cert #', display: true },
        cert_received_date: { id: 'cert_received_date', name: 'Cert Received Date', display: true },
        cert_process_date: { id: 'cert_process_date', name: 'Cert Process Date', display: false },
        status_process_date: { id: 'status_process_date', name: 'Status Process Date', display: false },
        region: { id: 'region', name: 'Region', display: false },
        school: { id: 'school', name: 'School', display: false },
        cert_expiration: { id: 'cert_expiration', name: 'Cert Expiration Date', display: false },
    });

    const [downloadingInProgress, setDownloadingInProgress] = useState(false)
    const [downloadingPageIndex, setDownloadingPageIndex] = useState(null)
    const [csvData, setCsvData] = useState(null)
    const [csvDataHeaders, setCsvDataHeaders] = useState(null)

    const [dailyGamesPostDisabled, setDailyGamesPostDisabled] = useState(false)
    const [shouldUpdateDailyGamesPostDisabled, setShouldUpdateDailyGamesPostDisabled] = useState(false)
    
    useEffect(() => {
       clickSearchButton()
       setHasFilter(false)
    }, [])

    useEffect(() => {
        if(!hasFilter){
            clickSearchButton(true)
        }
     }, [hasFilter])

    useEffect(() => {
        clickSearchButton()
    }, [pageSize, pageIndex])

    useEffect(() => {
        clickSearchButton()
    }, [displaySortBy, displayOrderBy])

    useEffect(() => {
        setShowSchoolName(false)
        if (!initialSearch) {
            setHasFilter(true)
        }
    }, [selectedDynamicsId, selectedOrderId, selectedSchoolName, selectedStartDate, selectedEndDate, sortBy, orderBy, setShowSchoolName])


    const clickSortColumn = (col) => {
        if (col === sortBy) {
            let updated_order_by = orderBy === 'ASC' ? 'DESC' : 'ASC'
            setOrderBy(updated_order_by, setDisplayOrderBy(updated_order_by))
        } else {
            setSortBy(col, setDisplaySortBy(col))
        }
    }

    const handleEnterPressed = (event) => {
        if(event.keyCode === 13){
            event.preventDefault();
            event.target.click()
            document.activeElement.blur()
            setTimeout(() => {event.target.focus()},500)
        }
    }

    const modalBlur = (event, id) => {
        if(event.relatedTarget){
            if(event.relatedTarget.getAttribute('data-modal') !== 'true'){
                event.preventDefault();
                document.getElementById(id).focus();
            }
        }
    }

    const refocusElement = useCallback((id, index) => {
        setTimeout(async () => {
            let element = document.getElementById(id);
            if(index < 10){
                if(element && element !== document.activeElement){
                    element.focus();
                }else{
                setTimeout(()=>{refocusElement(id, index+1)}, 50)
                }
            }
        }, 250);
    }, [])

    const clickSearchButton = async (clear=false) => {
        setSortBy(displaySortBy, setOrderBy(displayOrderBy))

        let body_data;

        if(clear){
            body_data = {
                dynamics_id: '',
                order_id: '',
                school_name: '',
                start_date_received: '',
                end_date_received: '',
                sort_by: sortBy,
                order_by: orderBy,
                page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                page_index: pageIndex,
            }
        }else{
            body_data = {
                dynamics_id: selectedDynamicsId,
                order_id: selectedOrderId,
                school_name: selectedSchoolName,
                start_date_received: selectedStartDate,
                end_date_received: selectedEndDate,
                sort_by: sortBy,
                order_by: orderBy,
                page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                page_index: pageIndex,
            }
        }

        var formBody = buildForm(body_data)
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        let jwt = await getUserJwt();
        var bearer = "Bearer " + jwt;
        headers.append("Authorization", bearer);

        fetch(`${getApiBaseUrl()}/order/searchAllOrderRequestsUsGames`, {
            method: 'post',
            headers: headers,
            body: formBody,
        })
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.data) {
                    if (initialSearch) {
                        setInitialSearch(false)
                    }

                    setDailyGamesPostDisabled(responseJson.data.disabled_config)
                    setRecords(responseJson.data.usgames)
                    setPageCount(responseJson.data.pages_count)
                    setAllowNoResults(true)
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }

    const clearSearchFilters = () => {
        setSelectedDynamicsId("")
        setSelectedOrderId("")
        setSelectedSchoolName('')
        setSelectedStartDate('')
        setSelectedEndDate('')
        setSortBy('dynamics_id')
        setDisplaySortBy('dynamics_id')
        setOrderBy('ASC')
        setDisplayOrderBy('ASC')
    }

    const clearSearch = async () => {
        await clearSearchFilters()
        setHasFilter(false)
    }

    const updatePageSize = (size) => {
        setPageSize(size, setPageIndex(0)  )
        clickSearchButton()
    }

    const updatePageIndex = (new_index) => {
        if(new_index === 'next'){
            new_index = pageIndex + 1
        }else if(new_index === 'prev'){
            new_index = pageIndex - 1
        }

        if(new_index >=0 && new_index < pageCount){
            setPageIndex(new_index)
        }
    }

    const handleDynamicsIdInputChange = (e) => {
        const value = e.target.value;
        if (isValidSearch(value)) {
          setSelectedDynamicsId(value);
        }
    };

    const isValidSearch = (val) => {
        let regDynamicsSearch = /^[a-zA-Z0-9-]*$/;
        return regDynamicsSearch.test(val);
    }

    const showColumnModal = (display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            let columns = {...allColumns};
            setModalContent(<SelectColumnsModal
                modalBlur={modalBlur} modalClose={showColumnModal}
                columns={columns} updateColumnDisplay={updateColumnDisplay}
            />)
            displayModal()
            refocusElement('select_columns_modal_dialog_close',0)
        } else {
            setModalContent("")
            closeModal()
            refocusElement('participant_activity_select_columns_button', 0);
        }
    }

    const updateColumnDisplay = (event, col) => {
        let new_column = allColumns[col]

        if(new_column){
            new_column.display = !new_column.display
            setAllColumns({
                ...allColumns,
                [col]: new_column
            })
        }
    }

    const exportToCsvHeaders = useCallback(async () => {
        let headers = [];
        let selected_columns = [];

        Object.keys(allColumns).forEach(col => {
            let element = allColumns[col]
            if(element.display){
                headers.push(element.name)
                selected_columns.push(element.id)
            }
        });
        setCsvData([headers])
        setCsvDataHeaders(selected_columns)
        setDownloadingPageIndex(0)
    }, [allColumns])

    const showDownloadingModal = useCallback((display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<DownloadingModal
                modalBlur={modalBlur} modalClose={showDownloadingModal}
                downloadingInProgress={true}
            />)
            displayModal()
            refocusElement('downloading_modal_dialog_close',0)
            setDownloadingInProgress(true)
            exportToCsvHeaders()
        } else {
            setDownloadingInProgress(false)
            setDownloadingPageIndex(null)
            setCsvData(null)
            setCsvDataHeaders(null)
            setModalContent("")
            closeModal()
            refocusElement('participant_activity_download_button', 0);
        }
    }, [closeModal, displayModal, exportToCsvHeaders, refocusElement, setModalContent])


    const downloadFile = useCallback(({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        showDownloadingModal(false)
    }, [showDownloadingModal])

    const showDownloadingError = useCallback(() => {
        setDownloadingInProgress(false)
        setDownloadingPageIndex(null)
        setCsvData(null)
        setCsvDataHeaders(null)

        setModalContent(<DownloadingModal
            modalBlur={modalBlur} modalClose={showDownloadingModal}
            downloadingInProgress={false}
        />)
    }, [setModalContent, showDownloadingModal])

    const escapeCsvField = (field) => {
        if (field === null || field === undefined) {
            return '';
        }
        if (typeof field !== 'string') {
            field = String(field);
        }
        if (field.includes(',') || field.includes('"') || field.includes('\n') || field.includes('\r') || field.startsWith(' ') || field.endsWith(' ')) {
            return `"${field.replace(/"/g, '""')}"`;
        }
        return field;
    }

    useEffect(() => {
        async function fetchData() {
            if(downloadingPageIndex != null && csvData){
                if(downloadingInProgress){
                    let download_page_size = 500;
                    let body_data = {
                        page_size: download_page_size,
                        page_index: downloadingPageIndex,
                        dynamics_id: selectedDynamicsId,
                        order_id: selectedOrderId,
                        school_name: selectedSchoolName,
                        start_date_received: selectedStartDate,
                        end_date_received: selectedEndDate,
                        sort_by: sortBy,
                        order_by: orderBy,
                    }

                    var formBody = buildForm(body_data)
                    var http_headers = new Headers();
                    http_headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                    let jwt = await getUserJwt()
                    var bearer = "Bearer " + jwt;
                    http_headers.append("Authorization", bearer);
            
                    fetch(`${getApiBaseUrl()}/order/searchAllOrderRequestsUsGames`, {
                        method: 'post',
                        headers: http_headers,
                        body: formBody,
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            let new_data = csvData
                            responseJson.data.usgames.forEach(element => {
                                let array = [];
                                csvDataHeaders.forEach(header => {
                                    array.push(escapeCsvField(element[header]))
                                });
                            
                                new_data.push(array)
                            });

                            setCsvData(new_data)

                            if(responseJson.data.usgames.length < download_page_size ){
                                let today = new Date();
                                let formattedDate = formatDate(today);
                    
                                downloadFile({
                                    data: new_data.map(row => row.join(',')).join('\n'),
                                    fileName: `USGamesGiveback${formattedDate}.csv`,
                                    fileType: 'text/csv',
                                })
                            }else{
                                setDownloadingPageIndex(downloadingPageIndex + 1)
                            }
                        }else{
                            showDownloadingError()
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        showDownloadingError()
                    });
                }else{
                    showDownloadingError()
                }
            }
        }
        fetchData()
    }, [downloadingPageIndex, csvData, csvDataHeaders, downloadFile, downloadingInProgress, getUserJwt, 
        orderBy, selectedDynamicsId, selectedOrderId, selectedSchoolName, selectedStartDate, selectedEndDate, showDownloadingError, sortBy])


    useEffect(() => {
        if(shouldUpdateDailyGamesPostDisabled){
            async function fetchData() {
                setShouldUpdateDailyGamesPostDisabled(false)
        
                    let body_data = {
                        config_name: 'daily_usgames_post_disabled',
                        config_value: 'false'
                    }
            
                    var formBody = buildForm(body_data)
                    var headers = new Headers();
                    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                    let jwt = await getUserJwt()
                    var bearer = "Bearer " + jwt;
                    headers.append("Authorization", bearer);
            
                    fetch(`${getApiBaseUrl()}/configuration/updateConfigAndHistory`, {
                        method: 'post',
                        headers: headers,
                        body: formBody,
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if(responseJson.success){
                            setDailyGamesPostDisabled(false)
                            setShouldUpdateDailyGamesPostDisabled(false)
                        }else{
                            showStandardModal(true, "Unable to restart. Please try again later.", "banner_action_button")
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        showStandardModal(true, "Unable to restart. Please try again later.", "banner_action_button")
                    });
                
            }
            fetchData()
        }
    }, [shouldUpdateDailyGamesPostDisabled, getUserJwt, StandardModal])

    const showStandardModal = useCallback((display, message, refocus_id) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<StandardModal
                modalBlur={modalBlur} modalClose={showStandardModal} refocusId={refocus_id} message={message}
            />)
            displayModal()
            refocusElement('standard_modal_dialog_close',0)
        } else {
            closeModal()
            refocusElement(refocus_id, 0);
        }
    }, [closeModal, displayModal, refocusElement, setModalContent])

    return (
        <div className="page_container">
            <div className='menu_content'>
                <GiveBackSideBar></GiveBackSideBar>
            </div>
            <div className="page_content">
            <h1 className="page_heading">US Games Report</h1>
            {
                dailyGamesPostDisabled && 
                <div className='general_banner'>
                    <div className='general_banner_message'>Warning - Threshold level has been reached pausing processing until reviewed.</div>
                    <button id="banner_action_button" className="red_button" onClick={() => setShouldUpdateDailyGamesPostDisabled(true)}>Restart</button>
                </div>
            }
                <div className="section_border">
                    <div className='search_section_info_text'>Select your search criteria below.</div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="usgames_search_dynamics_id">Dynamics ID</label>
                            <input type='text' id="usgames_search_dynamics_id" value={selectedDynamicsId} onChange={handleDynamicsIdInputChange} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="usgames_search_order_id">Order ID</label>
                            <input type='number' min="0" step="1" id="usgames_search_order_id" value={selectedOrderId} onChange={e => setSelectedOrderId(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="usgames_search_school_name">School Name</label>
                            <input type='text' id="usgames_search_school_name" value={selectedSchoolName} onChange={e => setSelectedSchoolName(e.target.value)} />
                        </div>
                    </div>
                <div className='flex_section search_section_row'>
                    <label htmlFor="usgames_search_school_name">Date Range</label>
                </div>
                <div className='flex_section search_section_row'>
                    <div className='search_section_item'>
                        <label htmlFor="usgames_search_start_date">Start Date</label>
                        <input type='date' id="usgames_search_start_date" value={selectedStartDate} onChange={e => setSelectedStartDate(e.target.value)} />
                    </div>
                    <div className='search_section_item'>
                        <label htmlFor="usgames_search_end_date">End Date</label>
                        <input type='date' id="usgames_search_end_date" value={selectedEndDate} onChange={e => setSelectedEndDate(e.target.value)} />
                    </div>
                    <div className='search_section_item'>
                        <label htmlFor="schools_search_sort_by">Sort By</label>
                        <select id="schools_search_sort_by" value={displaySortBy} onChange={e => setDisplaySortBy(e.target.value)}>
                            <option key="dynamics_id" value="dynamics_id" className={allColumns.dynamics_id.display ? "" : "hidden"}>Dynamics ID</option>
                            <option key="order_date" value="order_date" className={allColumns.order_date.display ? "" : "hidden"}>Order Date</option>
                            <option key="status" value="status" className={allColumns.status.display ? "" : "hidden"}>Status</option>
                            <option key="item_number" value="item_number" className={allColumns.item_number.display ? "" : "hidden"}>Item #</option>
                            <option key="gift_cert_number" value="gift_cert_number" className={allColumns.gift_cert_number.display ? "" : "hidden"}>Gift Cert #</option>
                            <option key="cert_received_date" value="cert_received_date" className={allColumns.cert_received_date.display ? "" : "hidden"}>Cert Received Date</option>
                            <option key="cert_process_date" value="cert_process_date" className={allColumns.cert_process_date.display ? "" : "hidden"}>Cert Process Date</option>
                            <option key="status_process_date" value="status_process_date" className={allColumns.status_process_date.display ? "" : "hidden"}>Status Process Date</option>
                            <option key="region" value="region" className={allColumns.region.display ? "" : "hidden"}>Region</option>
                            <option key="school" value="school" className={allColumns.school.display ? "" : "hidden"}>School</option>
                            <option key="cert_expiration" value="cert_expiration" className={allColumns.cert_expiration.display ? "" : "hidden"}>Cert Expiration Date</option>
                        </select>
                    </div>
                    <div className='search_section_item'>
                        <label htmlFor="schools_search_order_by">Order By</label>
                        <select id="schools_search_order_by" value={displayOrderBy} onChange={e => setDisplayOrderBy(e.target.value)}>
                            <option key="ASC" value="ASC">Ascending</option>
                            <option key="DESC" value="DESC">Descending</option>
                        </select>
                    </div>
                </div>
                    <div className='flex_section search_section_row search_section_buttons_row'>
                        <div className="search_section_error_text"></div>
                        <div className='search_section_search_buttons'>
                            <button className={"blue_text_button " + (hasFilter ? 'displayBlock' : 'displayHidden')} onClick={() => clearSearch()}>Clear Search</button>
                            <button className="red_button" onClick={() => clickSearchButton()}>Search</button>
                        </div>
                    </div>
                </div>

                <div className='flex_section search_section_table_heading_row'>
                    <div className='small_heading search_section_table_heading_label'>US Games Report Results</div>
                    <div>
                        <div className='search_section_table_buttons'>
                            <button id="participant_activity_select_columns_button" tabIndex="0" data-modal="true" className="red_outline_button" onClick={() => showColumnModal(true)}>Select Columns</button>
                            <button id="participant_activity_download_button" className="red_button" onClick={(event) => showDownloadingModal(true)}>Download</button>
                        </div>

                        <TablePageSizeOptions page_options={[10, 25, 50, 'All']} page_value={pageSize} page_action={updatePageSize} />
                    </div>
                </div>
                <div className="table_container">
                    <table>
                        <thead>
                            <tr>
                                {
                                   Object.entries(allColumns).map((col, index) => ( 
                                       <SortTableHeader key={index} column_label={col[1].name} column_id={col[1].id} sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!col[1].display} />
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                records.length > 0 ?
                                records.map((part, index) => (
                                    <tr id={`part${index}`} key={index}>
                                        {
                                            Object.entries(allColumns).map((col, index) => ( 
                                                <DataTableCell key={col[1].id}  name={col[1].id} row={part} columns={allColumns}></DataTableCell>
                                            ))
                                        }
                                    </tr>
                                ))
                                : <tr><td className='report_no_results' colSpan={100}>{allowNoResults ? 'No Results Found' : ''} </td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                {
                    pageCount > 1 && <SimplePagination pageCount={pageCount} pageIndex={pageIndex} clickAction={updatePageIndex}></SimplePagination>
                }
            </div>
        </div>
    )
};

function DataTableCell(props) {
    if (props.columns[props.name].display) {
        return (
            <td>{props.row[props.name]}</td>
        )
    } else {
        return <></>
    }
}

export default GiveBackUsGames;