import '../../modal.scss';

function SelectColumnsModal(props) {
    return (
        <div className='modal_dialog'>
            <div className="flex-column-reverse">
                <h2 className="modal_title">Select Columns</h2>
                <button id="select_columns_modal_dialog_close" onLoad={() => {this.focus()}} className="modal_dialog_close" 
                    onClick={() => {props.modalClose(false)}} 
                    onBlur={(event) => props.modalBlur(event, "select_checkbox_" + props.columns[Object.keys(props.columns)[Object.keys(props.columns).length - 1]].name )} 
                    aria-label="Close" >
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="currentColor"
                            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                        />
                    </svg>
                </button>
            </div>

            <div className='select_columns_section'>
                {
                    Object.keys(props.columns).map((key, index) => ( 
                        <div key={index} className="select_columns_checkbox_item">
                            <label className="checkbox_container">
                                <input id={"select_checkbox_" + props.columns[key].name} type="checkbox" data-modal="true"
                                    onClick={(event) => props.updateColumnDisplay(event, key)} defaultChecked={props.columns[key].display}
                                    aria-labelledby={"select_checkbox_label_" + props.columns[key].name} 
                                    onBlur={(event) => props.modalBlur(event, "select_columns_modal_dialog_close" )} />
                                <span className="checkbox_checkmark"></span>
                                <div id={"select_checkbox_label_" + props.columns[key].name} className="checkbox_label_text">{props.columns[key].name}</div>
                            </label>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SelectColumnsModal;