import './participantBadges.scss';
import '../../modal.scss';
function ParticipantBadgesModal(props) {
    return (
        <div className='modal_dialog'>
            <div className="flex-column-reverse">
                <h2 className="manage_badges_heading">{props.student.participant_first_name + " " + props.student.participant_last_name}</h2>
                <button id="manage_badges_modal_dialog_close" onLoad={() => {this.focus()}} className="modal_dialog_close" onClick={() => {props.manageBadges(false)}} 
                    onBlur={(event) => props.modalBlur(event, 'manage_badges_close_btn')} aria-label="Close manage badges modal" >
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="currentColor"
                            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                        />
                    </svg>
                </button>
            </div>
           
          
            <div className='manage_badges_section'>
                {
                    props.user_badges.map((badge, index) => ( 
                        <div key={badge.mission_id} className='manage_badges_item'>
                            <img className='manage_badges_image' src={badge.url} alt={badge.name}/>
                            <div id={"manage_badges_item" + index} className='manage_badges_item_label'>{badge.name}</div>
                            <div className="manage_badges_checkbox">
                                <label className="checkbox_container manage_badges_checkbox_container">
                                    <input id={"manage_badges_" + badge.mission_id} type="checkbox" data-modal="true"
                                        onClick={(event) => props.updateBadge(event, badge)} defaultChecked={badge.earned} disabled={!badge.action_group && !badge.mystery_gift}
                                        aria-labelledby={"manage_badges_item" + index} onBlur={props.onBlur} />
                                    <span className="checkbox_checkmark"></span>
                                </label>
                            </div>
                        </div> 
                    ))
                }
            </div>
            <button id="manage_badges_close_btn" className='red_button' 
            onClick={() => {props.manageBadges(false)}} 
            onBlur={(event) => props.modalBlur(event, 'manage_badges_modal_dialog_close')} 
            aria-label="Close manage badges modal">Close</button>
        </div>
    )
}

export default ParticipantBadgesModal;