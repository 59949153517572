import React from "react";
import SideBarItem from "../SideBarItem/SideBarItem";
import { useUser } from "../../context/UserContext";

import "./GiveBackSideBar.scss"

const GiveBackSideBar = () => {
    const {user} = useUser()

    return (
        <div className="side_bar">
            { user.pages && user.pages['giveback/directcontribution'] && <SideBarItem name="Direct Contribution" route="/giveback/directcontribution"></SideBarItem> }
            { user.pages && user.pages['giveback/usgames'] && <SideBarItem name="US Games" route="/giveback/usgames"></SideBarItem> }
        </div>
    );
};

export default GiveBackSideBar;