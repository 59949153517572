import React from "react";
import SideBarItem from "../SideBarItem/SideBarItem";

import "./ParticipantSideBar.scss"
import { useUser } from "../../context/UserContext";

const ParticipantSideBar = () => {
    const {user} = useUser()

    return (
        <div className="side_bar">
            { user.pages && user.pages['participants/badges'] && <SideBarItem name="Badges" route="/participants/badges"></SideBarItem> }
            { user.pages && user.pages['participants/activity'] && <SideBarItem name="Activity" route="/participants/activity"></SideBarItem> }
        </div>
    );
};

export default ParticipantSideBar;