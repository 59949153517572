import React, { useCallback, useEffect, useState } from 'react';
import './manageGlobalMessage.scss';
import ManageSideBar from '../../components/ManageSideBar/ManageSideBar';
import SimplePagination from '../../components/Pagination/SimplePagination';
import SortTableHeader from '../../components/SortTableHeader/SortTableHeader';
import TablePageSizeOptions from '../../components/TablePageSizeOptions/TablePageSizeOptions';
import { useUser } from '../../context/UserContext';
import { buildForm, getApiBaseUrl } from '../../helpers/globalFunctions';
import StandardModal from '../../modals/StandardModal/StandardModal';

const ManageGlobalMessage = ({displayModal, closeModal, setModalContent, setShowSchoolName}) => {
    const { getUserJwt } = useUser()

    const [initialSearch, setInitialSearch] = useState(true)
    const [allowNoResults, setAllowNoResults] = useState(false)
    const [shouldSearch, setShouldSearch] = useState(true)

    const [pageIndex, setPageIndex] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [records, setRecords] = useState([])
    const [selectedSearch, setSelectedSearch] = useState('')

    const [updatedMessage, setUpdatedMessage] = useState('')
    const [shouldUpdate, setShouldUpdate] = useState('')
    
    const [sortBy, setSortBy] = useState('history_date')
    const [orderBy, setOrderBy] = useState('DESC')

    const allColumns = {
        date: { id: 'history_date', name: 'Date' },
        created_by: { id: 'created_by', name: 'Created By' },
        message: { id: 'message', name: 'Message' },
        status: { id: 'status', name: 'Status' },
    }
    
    useEffect(() => {
        setShowSchoolName(false)
    })

    const refocusElement = useCallback((id, index) => {
        setTimeout(async () => {
            let element = document.getElementById(id);
            if(index < 10){
                if(element && element !== document.activeElement){
                    element.focus();
                }else{
                setTimeout(()=>{refocusElement(id, index+1)}, 50)
                }
            }
        }, 250);
    }, [])

    const showStandardModal = useCallback((display, message, refocus_id) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<StandardModal
                modalBlur={modalBlur} modalClose={showStandardModal} refocusId={refocus_id} message={message}
            />)
            displayModal()
            refocusElement('standard_modal_dialog_close',0)
        } else {
            closeModal()
            refocusElement(refocus_id, 0);
        }
    }, [closeModal, displayModal, refocusElement, setModalContent])

    const showGlobalMesageModal = useCallback((display) => {
        document.getElementById("global_message_textbox_container")?.classList.remove("required")
        setUpdatedMessage('')
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;

            setModalContent(<GlobalMessageModal setUpdatedMessage={setUpdatedMessage} saveMessage={setShouldUpdate}
                modalBlur={modalBlur} modalClose={showGlobalMesageModal} />)

            displayModal()
            refocusElement('global_message_modal_dialog_close',0)
        } else {
            setModalContent('')
            closeModal()
            refocusElement('add_global_message', 0);
        }
    }, [closeModal, displayModal, refocusElement, setModalContent])

    useEffect(() => {
        if(shouldUpdate){
            async function fetchData() {
                setShouldUpdate(false)
                if(updatedMessage){
                    let body_data = {
                        global_message_enabled: true,
                        global_message_content: updatedMessage
                    }
            
                    var formBody = buildForm(body_data)
                    var headers = new Headers();
                    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                    let jwt = await getUserJwt()
                    var bearer = "Bearer " + jwt;
                    headers.append("Authorization", bearer);
            
                    fetch(`${getApiBaseUrl()}/configuration/globalmessage`, {
                        method: 'post',
                        headers: headers,
                        body: formBody,
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if(responseJson.success){
                            document.getElementById("global_message_textbox_container")?.classList.remove("required")
                            if(document.getElementById("global_message_new")){
                                document.getElementById("global_message_new").value = null
                            }

                            setUpdatedMessage('')
                            setShouldSearch(true)
                            showGlobalMesageModal(false)
                        }else{
                            showStandardModal(true, "Unable to update global message. Please try again later.", "add_global_message")
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        showStandardModal(true, "Unable to update global message. Please try again later.", "add_global_message")
                    });
                }else{
                    document.getElementById("global_message_textbox_container")?.classList.add("required")
                }
            }
            fetchData()
        }
    }, [shouldUpdate, updatedMessage, getUserJwt, showGlobalMesageModal, showStandardModal])

    useEffect(() => {
        if(shouldSearch){
            async function fetchData() {
                setShouldSearch(false)
                let body_data = {
                    page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                    page_index: pageIndex,
                    search: selectedSearch,
                    sort_by: sortBy,
                    order_by: orderBy,
                }
        
                var formBody = buildForm(body_data)
                var headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                let jwt = await getUserJwt()
                var bearer = "Bearer " + jwt;
                headers.append("Authorization", bearer);
        
                fetch(`${getApiBaseUrl()}/configuration/adminReports/globalmessage`, {
                    method: 'post',
                    headers: headers,
                    body: formBody,
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if(initialSearch){
                            setInitialSearch(false) 
                        }
                        if (responseJson.data) {
                            setRecords(responseJson.data.records)
                            setPageCount(responseJson.data.pages_count)
                            setAllowNoResults(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    });
            }
            fetchData()
        }
        },[shouldSearch, getUserJwt, initialSearch, orderBy, pageIndex, pageSize, selectedSearch, sortBy]
    )

    const disableGlobalMessage = async () => {
        let body_data = {
            global_message_enabled: false,
        }

        var formBody = buildForm(body_data)
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        let jwt = await getUserJwt()
        var bearer = "Bearer " + jwt;
        headers.append("Authorization", bearer);

        fetch(`${getApiBaseUrl()}/configuration/globalmessage`, {
            method: 'post',
            headers: headers,
            body: formBody,
        })
        .then((response) => {
            return response.json()
        })
        .then((responseJson) => {
            if(responseJson.success){
                setShouldSearch(true)
            }else{
                showStandardModal(true, "Unable to disable global message. Please try again later.", "disable_global_message")
            }
        })
        .catch((error) => {
            console.error(error)
            showStandardModal(true, "Unable to disable global message. Please try again later.", "disable_global_message")
        });
    }

    const clickSortColumn = (col) => {
        if (col === sortBy) {
            let updated_order_by = orderBy === 'ASC' ? 'DESC' : 'ASC'
            setOrderBy(updated_order_by, setShouldSearch(true))
        } else {
            setSortBy(col, setShouldSearch(true))
        }
    }

    const handleEnterPressed = (event) => {
        if(event.keyCode === 13){
            event.preventDefault();
            event.target.click()
            document.activeElement.blur()
            setTimeout(() => {event.target.focus()},500)
        }
    }

    const modalBlur = (event, id) => {
        if(event.relatedTarget){
            if(event.relatedTarget.getAttribute('data-modal') !== 'true'){
                event.preventDefault();
                document.getElementById(id).focus();
            }
        }
    }

    const updatePageSize = (size) => {
        setPageSize(size, setPageIndex(0, setShouldSearch(true))  )
    }

    const updatePageIndex = (new_index) => {
        if(new_index === 'next'){
            new_index = pageIndex + 1
        }else if(new_index === 'prev'){
            new_index = pageIndex - 1
        }

        if(new_index >=0 && new_index < pageCount){
            setPageIndex(new_index, setShouldSearch(true))
        }
    }

    return (
        <div className="page_container">
            <div className='menu_content'>
                <ManageSideBar></ManageSideBar>
            </div>
            <div className="page_content">
                <h1 className="page_heading">Global Message</h1>
                <div className="section_border">
                    <div className='global_message_subtext'>Global messages can be shared with coordinators and visible in the HeadQuarters. Only one message can be enabled at a time.</div>
                    <div className='global_message_inputs'>
                        <div className='global_message_buttons'>
                            <button id="add_global_message" className={"red_button"} onClick={() => showGlobalMesageModal(true)}>Add a Global Message</button>  
                            <button id="disable_global_message" className={"red_outline_button"} onClick={() => disableGlobalMessage()}>Disable Global Message</button>       
                        </div>
                        <input type='text' className="search_input" id="global_message_search" aria-label="Global message search"
                            placeholder="Search" value={selectedSearch} onChange={e => setSelectedSearch(e.target.value, setShouldSearch(true))} />
                    </div>
                    <div>
                        <TablePageSizeOptions page_options={[10, 25, 50, 'All']} page_value={pageSize} page_action={updatePageSize} />
                    </div>
                    <div className="table_container">
                        <table>
                            <thead>
                                <tr>
                                    {
                                        Object.entries(allColumns).map((col, index) => ( 
                                            <SortTableHeader key={index} column_label={col[1].name} column_id={col[1].id} sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={false} />
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    records.length > 0 ?
                                    records.map((rec, index) => (
                                        <tr id={`part${index}`} key={index}>
                                            {
                                                Object.entries(allColumns).map((col, index) => ( 
                                                    <DataTableCell key={col[1].id}  name={col[1].id} row={rec} columns={allColumns}></DataTableCell>
                                                ))
                                            }
                                        </tr>
                                    ))
                                    : <tr><td className='report_no_results' colSpan={100}>{allowNoResults ? 'No Results Found' : ''} </td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        pageCount > 1 && <SimplePagination pageCount={pageCount} pageIndex={pageIndex} clickAction={updatePageIndex}></SimplePagination>
                    }
                </div>
            </div>
        </div>
    )
};

function DataTableCell(props) {
    return (
        props.name === 'status' ? 
        <td className={props.row[props.name] === 'Active' ? 'global_message_active' : ''}>{props.row[props.name]}</td>
        : 
        <td>{props.row[props.name]}</td>
    )
};

function GlobalMessageModal({setUpdatedMessage, modalClose, modalBlur, saveMessage}) {    
    return (
        <div className='modal_dialog add_global_message_dialog'>
             <div className="flex-column-reverse">
                <h2 className="modal_title modal_title_small">Add a Global Message</h2>
                <button id="global_message_modal_dialog_close" onLoad={() => {this.focus()}} className="modal_dialog_close" 
                    onClick={() => {modalClose(false)}} 
                    onBlur={(event) => modalBlur(event, "global_message_modal_dialog_cancel" )} 
                    aria-label="Close message dialog" >
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="currentColor"
                            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                        />
                    </svg>
                </button>
            </div>
            <div className="global_message_modal_message">Global Message</div>
            <div id="global_message_textbox_container" className='global_message_textbox_container'>
                <div className='required_text'>Message is required</div>
                <textarea id="global_message_new" data-modal="true" className='global_message_new_input'
                    onChange={ev => setUpdatedMessage(ev.target.value) }
                    rows="5" cols="50" maxLength={200}></textarea>
            </div> 
            
            <button id="global_message_modal_dialog_save" data-modal="true" className={"red_button global_message_modal_close_button"} 
                     aria-label="Save global message" onClick={() => saveMessage(true)}>Save</button>        

            <button id="global_message_modal_dialog_cancel" data-modal="true" className={"blue_text_button global_message_modal_close_button"} 
                    onClick={() => modalClose(false)} aria-label="Close message dialog"
                    onBlur={(event) => modalBlur(event, "global_message_modal_dialog_close" )} >Cancel</button>      
        </div>
    )
}

export default ManageGlobalMessage;