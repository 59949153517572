import './TablePageSizeOptions.scss';

function TablePageSizeOptions(props) {
    return ( 
       <div className='table_page_size_row'>
            <div className='table_page_show_label'>Show:</div>
            {
                props.page_options.map((item, index) => (
                    <button onClick={() => props.page_action(item)} aria-label={`Show ${item} Students`} className={"table_page_size_button " + (props.page_value === item ? 'table_page_size_button_active': '')} key={item}>{item}</button>
                ))
            }
       </div>
    )
  }

  export default TablePageSizeOptions;