import { Oval } from 'react-loader-spinner';
import '../../modal.scss';
import './DownloadingModal.scss';

function DownloadingModal(props) {
    return (
        <div className='modal_dialog'>
            <div className="flex-column-reverse">
                <h2 className="modal_title">Downloading</h2>
                <button id="downloading_modal_dialog_close" onLoad={() => {this.focus()}} className="modal_dialog_close" 
                    onClick={() => {props.modalClose(false)}} 
                    onBlur={(event) => props.modalBlur(event, "downloading_modal_dialog_cancel" )} 
                    aria-label="Close Download Modal" >
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="currentColor"
                            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                        />
                    </svg>
                </button>
            </div>
            <div>
                <div className='downloading_modal_loading'>  
                    {
                        props.downloadingInProgress === true ? 
                            <Oval
                                visible={true}
                                height="50"
                                width="50"
                                strokeWidth="9"
                                color="#003399"
                                secondaryColor="#003399"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        : <div className='small_error_text'>There was an error with the download. Please try again later. </div>
                    }
                </div>
                <button id="downloading_modal_dialog_cancel" className={"blue_text_button download_cancel_button"} 
                    onClick={() => props.modalClose(false)} aria-label="Cancel Download"
                    onBlur={(event) => props.modalBlur(event, "downloading_modal_dialog_close" )} >Cancel</button>        
            </div>
        </div>
    )
}

export default DownloadingModal;