import React, { useCallback, useEffect, useState } from 'react';
import './manageDataMismatch.scss';
import '../../Theme.scss'
import { useUser } from '../../context/UserContext';
import { buildForm, formatDate, getApiBaseUrl } from '../../helpers/globalFunctions';
import SortTableHeader from '../../components/SortTableHeader/SortTableHeader';
import TablePageSizeOptions from '../../components/TablePageSizeOptions/TablePageSizeOptions';
import ManageSideBar from '../../components/ManageSideBar/ManageSideBar';
import SimplePagination from '../../components/Pagination/SimplePagination';
import SelectColumnsModal from '../../modals/SelectColumnsModal/SelectColumnsModal';
import DownloadingModal from '../../modals/DownloadingModal/DownloadingModal';
import StandardModal from '../../modals/StandardModal/StandardModal';


const ManageDataMismatch = ({displayModal, closeModal, setModalContent, setShowSchoolName}) => {
    const { getUserJwt } = useUser()

    const [initialSearch, setInitialSearch] = useState(true)
    const [allowNoResults, setAllowNoResults] = useState(false)
    const [shouldSearch, setShouldSearch] = useState(true)
    const [hasFilter, setHasFilter] = useState(false)

    const [pageIndex, setPageIndex] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [records, setRecords] = useState([])

    const [selectedSchoolId, setSelectedSchoolId] = useState('')
    const [selectedSchoolName, setSelectedSchoolName] = useState('')
    const [selectedCoordinatorId, setSelectedCoordinatorId] = useState('')
    const [selectedCoordinatorName, setSelectedCoordinatorName] = useState('')
    const [selectedTeamId, setSelectedTeamId] = useState('')
    const [selectedTeamName, setSelectedTeamName] = useState('')
    const [selectedTeamCaptainId, setSelectedTeamCaptainId] = useState('')
    const [selectedMisMatchType, setSelectedMismatchType] = useState('All')
    
    const [sortBy, setSortBy] = useState('school_id')
    const [displaySortBy, setDisplaySortBy] = useState('school_id')
    const [orderBy, setOrderBy] = useState('ASC')
    const [displayOrderBy, setDisplayOrderBy] = useState('ASC')

    const [allColumns, setAllColumns] = useState({
        school_id: { id: 'school_id', name: 'School Id', display: true },
        school_name: { id: 'school_name', name: 'School Name', display: true },
        event_id: { id: 'event_id', name: 'Event Id', display: true },
        event_name: { id: 'event_name', name: 'Event Name', display: true },
        region: { id: 'region', name: 'Region', display: true },
        team_id: { id: 'team_id', name: 'Team Id', display: true },
        team_name: { id: 'team_name', name: 'Team Name', display: true },
        team_captain_id: { id: 'team_captain_id', name: 'Team Captain Id', display: true },
        team_captain_name: { id: 'team_captain_name', name: 'Team Captain Name', display: true },
        coordinator_id: { id: 'coordinator_id', name: 'Coordinator Id', display: true },
        coordinator_name: { id: 'coordinator_name', name: 'Coordinator Name', display: true },
        school_type: { id: 'school_type', name: 'School Type', display: true },
        mismatch_type: { id: 'mismatch_type', name: 'Mismatch Type', display: true },
    });

    const [downloadingInProgress, setDownloadingInProgress] = useState(false)
    const [downloadingPageIndex, setDownloadingPageIndex] = useState(null)
    const [csvData, setCsvData] = useState(null)
    const [csvDataHeaders, setCsvDataHeaders] = useState(null)
    
    useEffect(() => {
        setShowSchoolName(false)
    })

    useEffect(() => {
        if((selectedSchoolId || selectedSchoolName || selectedCoordinatorId || selectedCoordinatorName || selectedTeamId
            || selectedTeamName || selectedTeamCaptainId || selectedMisMatchType !== 'All') && !initialSearch){
               setHasFilter(true)
            }else{ 
                setHasFilter(false)
            }
    }, [initialSearch,
        selectedSchoolId,
        selectedSchoolName,
        selectedCoordinatorId,
        selectedCoordinatorName,
        selectedTeamId,
        selectedTeamName,
        selectedTeamCaptainId,
        selectedMisMatchType,])

    useEffect(() => {
        if(shouldSearch){
            async function fetchData() {
                setShouldSearch(false)
                let body_data = {
                    page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                    page_index: pageIndex,
                    school_id: selectedSchoolId,
                    school_name: selectedSchoolName,
                    coordinator_id: selectedCoordinatorId,
                    coordinator_name: selectedCoordinatorName,
                    team_id: selectedTeamId,
                    team_name: selectedTeamName,
                    team_captain_id: selectedTeamCaptainId,
                    mismatch_type: selectedMisMatchType,
                    sort_by: sortBy,
                    order_by: orderBy,
                }
        
                var formBody = buildForm(body_data)
                var headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                let jwt = await getUserJwt()
                var bearer = "Bearer " + jwt;
                headers.append("Authorization", bearer);
        
                fetch(`${getApiBaseUrl()}/school/adminReports/dataMismatch`, {
                    method: 'post',
                    headers: headers,
                    body: formBody,
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if(initialSearch){
                            setInitialSearch(false) 
                        }
                        if (responseJson.data) {
                            setRecords(responseJson.data.records)
                            setPageCount(responseJson.data.pages_count)
                            setAllowNoResults(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    });
            }
            fetchData()
        }
        },[shouldSearch, getUserJwt, initialSearch, orderBy, pageIndex, pageSize, selectedCoordinatorId,selectedCoordinatorName, selectedMisMatchType,
        selectedSchoolId, selectedSchoolName, selectedTeamCaptainId, selectedTeamId, selectedTeamName, sortBy]
    )

    const clickSortColumn = (col) => {
        if (col === sortBy) {
            let updated_order_by = orderBy === 'ASC' ? 'DESC' : 'ASC'
            setOrderBy(updated_order_by, setDisplayOrderBy(updated_order_by, setShouldSearch(true)))
        } else {
            setSortBy(col, setDisplaySortBy(col, setShouldSearch(true)))
        }
    }

    const handleEnterPressed = (event) => {
        if(event.keyCode === 13){
            event.preventDefault();
            event.target.click()
            document.activeElement.blur()
            setTimeout(() => {event.target.focus()},500)
        }
    }

    const modalBlur = (event, id) => {
        if(event.relatedTarget){
            if(event.relatedTarget.getAttribute('data-modal') !== 'true'){
                event.preventDefault();
                document.getElementById(id).focus();
            }
        }
    }

    const refocusElement = useCallback((id, index) => {
        setTimeout(async () => {
            let element = document.getElementById(id);
            if(index < 10){
                if(element && element !== document.activeElement){
                    element.focus();
                }else{
                setTimeout(()=>{refocusElement(id, index+1)}, 50)
                }
            }
        }, 250);
    }, [])

    const clickSearchButton = (col) => {
        setSortBy(displaySortBy, setOrderBy(displayOrderBy, setShouldSearch(true)))
    }

    const clearSearchFilters = () => {
        setSelectedSchoolId('')
        setSelectedSchoolName('')
        setSelectedCoordinatorId('')
        setSelectedCoordinatorName('')
        setSelectedTeamId('')
        setSelectedTeamName('')
        setSelectedTeamCaptainId('')
        setSelectedMismatchType('All')
        setSortBy('school_id')
        setDisplaySortBy('school_id')
        setOrderBy('ASC')
        setDisplayOrderBy('ASC')
    }

    const clearSearch = async () => {
        await clearSearchFilters()
        setHasFilter(false, setShouldSearch(true))
    }

    const updatePageSize = (size) => {
        setPageSize(size, setPageIndex(0, setShouldSearch(true))  )
    }

    const updatePageIndex = (new_index) => {
        if(new_index === 'next'){
            new_index = pageIndex + 1
        }else if(new_index === 'prev'){
            new_index = pageIndex - 1
        }

        if(new_index >=0 && new_index < pageCount){
            setPageIndex(new_index, setShouldSearch(true))
        }
    }

    const showColumnModal = (display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<SelectColumnsModal
                modalBlur={modalBlur} modalClose={showColumnModal}
                columns={allColumns} updateColumnDisplay={updateColumnDisplay}
            />)
            displayModal()
            refocusElement('select_columns_modal_dialog_close',0)
        } else {
            setModalContent("")
            closeModal()
            refocusElement('data_mismatch_select_columns_button', 0);
        }
    }

    const updateColumnDisplay = (event, col) => {
        let new_column = allColumns[col]

        if(new_column){
            new_column.display = !new_column.display
            setAllColumns({
                ...allColumns,
                [col]: new_column
            })
        }
    }

    const syncData = async (event_id, company_id, team_id, index) => {
        let body_data = {}

        if(event_id){
            body_data.event_id = event_id
        }
        if(company_id){
            body_data.company_id = company_id
        }
        if(team_id){
            body_data.team_id = team_id
        }

        var formBody = buildForm(body_data)
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        let jwt = await getUserJwt()
        var bearer = "Bearer " + jwt;
        headers.append("Authorization", bearer);

        fetch(`${getApiBaseUrl()}/school/adminReports/syncSchoolTeam`, {
            method: 'post',
            headers: headers,
            body: formBody,
        })
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                if(responseJson.success){
                    showStandardModal(true, "Team and Company data have been resynced.", "sync_data_" + index)
                    setShouldSearch(true)
                }else{
                    showStandardModal(true, "Data was not able to be resynced. Please try again later.", "sync_data_" + index)
                }
            })
            .catch((error) => {
                console.error(error)
                showStandardModal(true, "Data was not able to be resynced. Please try again later.", "sync_data_" + index)
            });
    }

    const exportToCsvHeaders = useCallback(async () => {
        let headers = [];
        let selected_columns = [];

        Object.keys(allColumns).forEach(col => {
            let element = allColumns[col]
            if(element.display){
                headers.push(element.name)
                selected_columns.push(element.id)
            }
        });

        setCsvData([headers])
        setCsvDataHeaders(selected_columns)
        setDownloadingPageIndex(0)
    }, [allColumns])

    const showStandardModal = useCallback((display, message, refocus_id) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<StandardModal
                modalBlur={modalBlur} modalClose={showStandardModal} refocusId={refocus_id} message={message}
            />)
            displayModal()
            refocusElement('standard_modal_dialog_close',0)
        } else {
            closeModal()
            refocusElement(refocus_id, 0);
        }
    }, [closeModal, displayModal, refocusElement, setModalContent])

    const showDownloadingModal = useCallback((display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<DownloadingModal
                modalBlur={modalBlur} modalClose={showDownloadingModal}
                downloadingInProgress={true}
            />)
            displayModal()
            refocusElement('downloading_modal_dialog_close',0)
            setDownloadingInProgress(true)
            exportToCsvHeaders()
        } else {
            setDownloadingInProgress(false)
            setDownloadingPageIndex(null)
            setCsvData(null)
            setCsvDataHeaders(null)
            setModalContent("")
            closeModal()
            refocusElement('data_mismatch_download_button', 0);
        }
    }, [closeModal, displayModal, exportToCsvHeaders, refocusElement, setModalContent])


    const downloadFile = useCallback(({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        showDownloadingModal(false)
    }, [showDownloadingModal])

    const showDownloadingError = useCallback(() => {
        setDownloadingInProgress(false)
        setDownloadingPageIndex(null)
        setCsvData(null)
        setCsvDataHeaders(null)

        setModalContent(<DownloadingModal
            modalBlur={modalBlur} modalClose={showDownloadingModal}
            downloadingInProgress={false}
        />)
    }, [setModalContent, showDownloadingModal])

    const escapeCsvField = (field) => {
        if (field === null || field === undefined) {
            return '';
        }
        if (typeof field !== 'string') {
            field = String(field);
        }
        if (field.includes(',') || field.includes('"') || field.includes('\n') || field.includes('\r') || field.startsWith(' ') || field.endsWith(' ')) {
            return `"${field.replace(/"/g, '""')}"`;
        }
        return field;
    }

    useEffect(() => {
        async function fetchData() {
            if(downloadingPageIndex !== null && csvData){
                if(downloadingInProgress){
                    let download_page_size = 500;
                    let body_data = {
                        page_size: download_page_size,
                        page_index: downloadingPageIndex,
                        school_id: selectedSchoolId,
                        school_name: selectedSchoolName,
                        coordinator_id: selectedCoordinatorId,
                        coordinator_name: selectedCoordinatorName,
                        team_id: selectedTeamId,
                        team_name: selectedTeamName,
                        team_captain_id: selectedTeamCaptainId,
                        mismatch_type: selectedMisMatchType,
                        sort_by: sortBy,
                        order_by: orderBy,
                    }

                    var formBody = buildForm(body_data)
                    var http_headers = new Headers();
                    http_headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                    let jwt = await getUserJwt()
                    var bearer = "Bearer " + jwt;
                    http_headers.append("Authorization", bearer);
            
                    fetch(`${getApiBaseUrl()}/school/adminReports/dataMismatch`, {
                        method: 'post',
                        headers: http_headers,
                        body: formBody,
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            let new_data = csvData
                            responseJson.data.records.forEach(element => {
                                let array = [];
                                csvDataHeaders.forEach(header => {
                                    array.push(escapeCsvField(element[header]))
                                });
                            
                                new_data.push(array)
                            });

                            setCsvData(new_data)

                            if(responseJson.data.records.length < download_page_size ){
                                let today = new Date();
                                let formattedDate = formatDate(today);
                    
                                downloadFile({
                                    data: new_data.map(row => row.join(',')).join('\n'),
                                    fileName: `dataMismatch${formattedDate}.csv`,
                                    fileType: 'text/csv',
                                })
                            }else{
                                setDownloadingPageIndex(downloadingPageIndex + 1)
                            }
                        }else{
                            showDownloadingError()
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        showDownloadingError()
                    });
                }else{
                    showDownloadingError()
                }
            }
        }
        fetchData()
    }, [downloadingPageIndex, csvData, csvDataHeaders, downloadFile, downloadingInProgress, getUserJwt, orderBy, selectedCoordinatorId, 
        selectedCoordinatorName, selectedMisMatchType, selectedSchoolId, selectedSchoolName, selectedTeamCaptainId,
        selectedTeamId, selectedTeamName, showDownloadingError, sortBy])

    
    return (
        <div className="page_container">
            <div className='menu_content'>
                <ManageSideBar></ManageSideBar>
            </div>
            <div className="page_content">
                <h1 className="page_heading">Data Mismatch</h1>
                <div className="section_border">
                    <div className='search_section_info_text'>Select your search criteria below.</div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="data_mismatch_school_id">School ID</label>
                            <input type='number' min="0" step="1" id="data_mismatch_school_id" value={selectedSchoolId} onChange={e => setSelectedSchoolId(e.target.value)} />
                        </div>

                        <div className='search_section_item'>
                            <label htmlFor="data_mismatch_school_name">School Name</label>
                            <input type='text' id="data_mismatch_school_name" value={selectedSchoolName} onChange={e => setSelectedSchoolName(e.target.value)} />
                        </div>

                        <div className='search_section_item'>
                            <label htmlFor="data_mismatch_coordinator_id">Coordinator ID</label>
                            <input type='number' min="0" step="1" id="data_mismatch_coordinator_id" value={selectedCoordinatorId} onChange={e => setSelectedCoordinatorId(e.target.value)} />
                        </div>

                        <div className='search_section_item'>
                            <label htmlFor="data_mismatch_coordinator_name">Coordinator Name</label>
                            <input type='text' id="data_mismatch_coordinator_name" value={selectedCoordinatorName} onChange={e => setSelectedCoordinatorName(e.target.value)} />
                        </div>
                    </div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="data_mismatch_team_id">Team ID</label>
                            <input type='number' min="0" step="1" id="data_mismatch_team_id" value={selectedTeamId} onChange={e => setSelectedTeamId(e.target.value)} />
                        </div>

                        <div className='search_section_item'>
                            <label htmlFor="data_mismatch_team_name">Team Name</label>
                            <input type='text' id="data_mismatch_team_name" value={selectedTeamName} onChange={e => setSelectedTeamName(e.target.value)} />
                        </div>

                        <div className='search_section_item'>
                            <label htmlFor="data_mismatch_team_captain_id">Team Captain ID</label>
                            <input type='number' min="0" step="1" id="data_mismatch_team_captain_id" value={selectedTeamCaptainId} onChange={e => setSelectedTeamCaptainId(e.target.value)} />
                        </div>
                    </div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="data_mismatch_mismatch_type">Data Mismatch Type</label>
                            <select id="data_mismatch_mismatch_type" value={selectedMisMatchType} onChange={e => setSelectedMismatchType(e.target.value)}>
                                <option key="All" value="All">All Types</option>
                                <option key="Coordinator and Team Mismatch" value="Coordinator and Team Mismatch">Coordinator and Team Mismatch</option>
                                <option key="Missing Team Captain" value="Missing Team Captain">Missing Team Captain</option>
                                <option key="Missing Coordinator" value="Missing Coordinator">Missing Coordinator</option>
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_sort_by">Sort By</label>
                            <select id="schools_search_sort_by" value={displaySortBy} onChange={e => setDisplaySortBy(e.target.value)}>
                                {
                                    Object.entries(allColumns).map((col, index) => ( 
                                        <option key={index} value={col[1].id} className={col[1].display ? "" : "hidden"}>{col[1].name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_order_by">Order By</label>
                            <select id="schools_search_order_by" value={displayOrderBy} onChange={e => setDisplayOrderBy(e.target.value)}>
                                <option key="ASC" value="ASC">Ascending</option>
                                <option key="DESC" value="DESC">Descending</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex_section search_section_row search_section_buttons_row search_section_buttons_row_right'>
                        <div className='search_section_search_buttons'>
                            <button className={"blue_text_button " + (hasFilter ? 'displayBlock' : 'displayHidden')} onClick={() => clearSearch()}>Clear Search</button>
                            <button className="red_button" onClick={() => clickSearchButton()}>Search</button>
                        </div>
                    </div>
                </div>
                <div className='flex_section search_section_table_heading_row'>
                    <div className='small_heading search_section_table_heading_label'>Data Mismatch Search Results</div>
                    <div>
                        <div className='search_section_table_buttons'>
                            <button id="data_mismatch_select_columns_button" tabIndex="0" data-modal="true" className="red_outline_button" onClick={() => showColumnModal(true)}>Select Columns</button>
                            <button id="data_mismatch_download_button" className="red_button" onClick={(event) => showDownloadingModal(true)}>Download</button>
                        </div>

                        <TablePageSizeOptions page_options={[10, 25, 50, 'All']} page_value={pageSize} page_action={updatePageSize} />
                    </div>
                </div>
                <div className="table_container">
                    <table>
                        <thead>
                            <tr>
                                {
                                   Object.entries(allColumns).map((col, index) => ( 
                                       <SortTableHeader key={index} column_label={col[1].name} column_id={col[1].id} sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!col[1].display} />
                                    ))
                                }
                                 <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                records.length > 0 ?
                                records.map((rec, index) => (
                                    <tr id={`part${index}`} key={index}>
                                        {
                                            Object.entries(allColumns).map((col, index) => ( 
                                                <DataTableCell key={col[1].id}  name={col[1].id} row={rec} columns={allColumns}></DataTableCell>
                                            ))
                                        }
                                         <td>
                                            <button id={"sync_data_"+index} className='blue_text_button' aria-label={"Sync data for  " + (rec.school_name ? rec.school_name : rec.team_name)} onClick={() => syncData(rec.event_id, rec.company_id, rec.team_id, index )}>Run</button>
                                        </td>
                                    </tr>
                                ))
                                : <tr><td className='report_no_results' colSpan={100}>{allowNoResults ? 'No Results Found' : ''} </td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                {
                    pageCount > 1 && <SimplePagination pageCount={pageCount} pageIndex={pageIndex} clickAction={updatePageIndex}></SimplePagination>
                }
            </div>
        </div>
    )
};

function DataTableCell(props) {
    if (props.columns[props.name].display) {
        return (
            <td>{props.row[props.name]}</td>
        )
    } else {
        return <></>
    }
};

export default ManageDataMismatch;