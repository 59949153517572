import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import "./Navbar.scss"
import { useUser } from "../../context/UserContext";

const Navbar = (props) => {
    const {user} = useUser()
    const [open, setOpen] = useState(false);
    const [showSection, setShowSection] = useState(null)

    const firstName = user?.first_name
    const event_id = user?.event_id
    const company_id = user?.company_id

    let main_school_route = null;
    let main_participant_route = null;
    let main_giveback_route = null;
    let main_manage_route = null;

    if(user && user.pages){
        let school_sub_pages = [
            'school/summary',
            'school/plan',
            'school/instantgifts',
            'school/staff',
            'school/report',
            'school/emails',
            'school/orderposteventgifts',
            'school/giveback',
        ]

        if(event_id && company_id){
            school_sub_pages.forEach(pg => {
                if(!main_school_route && user.pages[pg]){
                    main_school_route = pg
                }
            });
        }else{
            if(user.pages['school']){
                main_school_route = 'school'
            }
        }
        
        let participant_pages = [
            'participants/badges',
            'participants/activity'
        ]
        participant_pages.forEach(pg => {
            if(!main_participant_route && user.pages[pg]){
                main_participant_route = pg
            }
        });
    
        let giveback_pages = [
            'giveback/directcontribution',
            'giveback/usgames',
        ]
        giveback_pages.forEach(pg => {
            if(!main_giveback_route && user.pages[pg]){
                main_giveback_route = pg
            }
        });

        let manage_pages = [
            'manage/emails',
            'manage/datamismatch',
            'manage/globalmessage',
        ]
        manage_pages.forEach(pg => {
            if(!main_manage_route && user.pages[pg]){
                main_manage_route = pg
            }
        });    
    }


    const location = useLocation();

    const openMobileNav = () => {
        if(!open){
            let url_pieces = location.pathname.split('/')
            let section = url_pieces[1]
            if(section === 'school'){
                if(!event_id || !company_id) {
                    section = null
                }
            }
            setShowSection(section)
        }
        setOpen(!open)
    }

    const showHideMobileSection = (name) => {
        if(name === showSection){
            name = null
        }
        setShowSection(name)
    }

    return (
        <div className="navbar_section">
            <img className="navbar_logo" src={props.logo_image} alt="American Heart Association Kids Heart Challenge American Heart Challenge Logo" />
            {user && user.is_authenticated && 
                <nav>
                    <div className="navbar_items">
                        { main_school_route && <NavLink to={main_school_route}  className="navbar_link">Schools</NavLink> }
                        { main_participant_route && <NavLink to={main_participant_route}  className="navbar_link">Participants</NavLink> }
                        { main_giveback_route && <NavLink to={main_giveback_route}  className="navbar_link">School Give Back</NavLink> }
                        { main_manage_route && <NavLink to={main_manage_route}  className="navbar_link">Manage</NavLink> }
                        <div className="navbar_link navbar_link_name">Welcome, {firstName}!</div>
                        <NavLink to="/logout"  className="navbar_link navbar_link_button">Logout</NavLink>
                    </div>
                    <div className="navbar_mobile">
                        <div className="navber_mobile_heading">
                            <div className="navbar_link_name_mobile">Welcome, {firstName}!</div>
                            <NavLink to="/logout"  className="navbar_link_button_mobile">Logout</NavLink>
                            <div className="navbar_mobile_menu_button_container" onClick={()=> openMobileNav()}>
                                {
                                    open ?
                                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" height="22" width="22">
                                            <path d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z" />
                                        </svg>
                                    :
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="2.25 4 11.5 9.5" height="22" width="22">
                                            <path d="M 3 12 H 13 A 0.5 0.5 0 0 1 13 13.5 H 3 A 0.5 0.5 0 0 1 3 12 Z M 3 8 H 13 A 0.5 0.5 0 0 1 13 9.5 H 3 A 0.5 0.5 0 0 1 3 8 Z M 3 4 H 13 A 0.5 0.5 0 0 1 13 5.5 H 3 A 0.5 0.5 0 0 1 3 4 Z" fill="#000000"/>
                                        </svg>
                                }
                            </div>
                        </div>
                        {
                            open && 
                            <div className="navbar_items_mobile">
                                {
                                    event_id && company_id && main_school_route ? 
                                    <MobileNavSectionButton name="school" title="School" updateDisplay={showHideMobileSection} showSection={showSection}></MobileNavSectionButton>
                                    :
                                    main_school_route &&
                                    <NavLink to="/school"  className="navbar_link_mobile single" onClick={()=> setOpen(false, setShowSection(null))}>School</NavLink>
                                }
                                    {
                                    main_school_route && showSection === 'school' &&
                                        <div className="navbar_subsection_mobile">
                                           	{ user.pages && user.pages['school/summary'] && <NavLink to={"/school/summary/"+event_id+"/"+company_id} onClick={()=> setOpen(false)}  className="navbar_sublink_mobile">Summary</NavLink> }
                                            { user.pages && user.pages['school/plan'] && <NavLink to={"/school/plan/"+event_id+"/"+company_id} onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Plan</NavLink> }
                                            { user.pages && user.pages['school/instantgifts'] && <NavLink to={"/school/instantgifts/"+event_id+"/"+company_id} onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Manage Instant Gifts</NavLink> }
                                            { user.pages && user.pages['school/staff'] && <NavLink to={"/school/staff/"+event_id+"/"+company_id} onClick={()=> setOpen(false)} className="navbar_sublink_mobile">District/School Staff</NavLink> }
                                            { user.pages && user.pages['school/report'] && <NavLink to={"/school/report/"+event_id+"/"+company_id} onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Report</NavLink> }
                                            { user.pages && user.pages['school/emails'] && <NavLink to={"/school/emails/"+event_id+"/"+company_id} onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Emails</NavLink> }
                                            { user.pages && user.pages['school/orderposteventgifts'] && <NavLink to={"/school/orderposteventgifts/"+event_id+"/"+company_id} onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Order Post-Event Gifts</NavLink> }
                                            { user.pages && user.pages['school/giveback'] && <NavLink to={"/school/giveback/"+event_id+"/"+company_id} onClick={()=> setOpen(false)} className="navbar_sublink_mobile">School Give-Back</NavLink> }
                                        </div>
                                    }
                                { main_participant_route && <MobileNavSectionButton name="participants" title="Participants" updateDisplay={showHideMobileSection} showSection={showSection}></MobileNavSectionButton> }
                                    {
                                        main_participant_route && showSection === 'participants' &&
                                            <div className="navbar_subsection_mobile">
                                                	{ user.pages && user.pages['participants/badges'] && <NavLink to="/participants/badges" onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Badges</NavLink> }
                                                	{ user.pages && user.pages['participants/activity'] && <NavLink to="/participants/activity" onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Activity</NavLink> }
                                            </div>
                                    }
                                { main_giveback_route && <MobileNavSectionButton name="giveback" title="School Give Back" updateDisplay={showHideMobileSection} showSection={showSection}></MobileNavSectionButton> }
                                    {
                                        main_giveback_route && showSection === 'giveback' &&
                                            <div className="navbar_subsection_mobile">
                                                { user.pages && user.pages['giveback/directcontribution'] && <NavLink to="/giveback/directcontribution" onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Direct Contribution</NavLink> }
                                                { user.pages && user.pages['giveback/usgames'] && <NavLink to="/giveback/usgames" onClick={()=> setOpen(false)} className="navbar_sublink_mobile">US Games</NavLink> }
                                            </div>
                                    }
                                { main_manage_route && <MobileNavSectionButton name="manage" title="Manage" updateDisplay={showHideMobileSection} showSection={showSection}></MobileNavSectionButton> }
                                    {
                                        main_manage_route && showSection === 'manage' &&
                                            <div className="navbar_subsection_mobile">
                                                { user.pages && user.pages['manage/emails'] && <NavLink to="/manage/emails" onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Emails</NavLink> }
                                                { user.pages && user.pages['manage/globalmessage'] && <NavLink to="/manage/globalmessage" onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Global Message</NavLink> }
                                                { user.pages && user.pages['manage/datamismatch'] && <NavLink to="/manage/datamismatch" onClick={()=> setOpen(false)} className="navbar_sublink_mobile">Data Mismatch</NavLink> }
                                            </div>
                                    }
                            </div>
                        }
                    </div>
                </nav>
            }       
        </div>
    );
};

function MobileNavSectionButton(props) {
    let name = props.name
    let title = props.title

     return <button className={"navbar_link_mobile " +  (props.showSection === name ? 'active' : "")} onClick={()=> props.updateDisplay(name)}>
        {title}
        {
            props.showSection === name ? 
            <svg className="navbar_section_expand_icon" xmlns="http://www.w3.org/2000/svg" viewBox="2 7 12 2" width="15" height="15">
                <path d="M 7 7 V 7 H 13 A 0.5 0.5 0 0 1 13 9 H 9 V 9 H 3 A 0.5 0.5 0 0 1 3 7 Z" fill="#000000"/>
            </svg>
            : 
            <svg className="navbar_section_expand_icon" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 12 12" width="15" height="15">
                <path d="M 7 3 A 0.5 0.5 0 0 1 9 3 V 7 H 13 A 0.5 0.5 0 0 1 13 9 H 9 V 13 A 0.5 0.5 0 0 1 7 13 V 9 H 3 A 0.5 0.5 0 0 1 3 7 H 7 V 3 Z" fill="#000000"/>
            </svg>
        }
    </button>
}

export default Navbar;