import React from 'react'
 
const Progress_bar = ({bgcolor,progress,height}) => {
    
    const Parentdiv = {
        height: height,
        width: '95%',
        backgroundColor: 'lightgrey',
        borderRadius: 40,
        margin: 10
      }
     
      const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius:40,
        textAlign: 'right'
      }
     
      const progresstext = {
        padding: 10,
        color: 'black',
        fontWeight: 900,
        display: 'none'
      }
       
    return (
    <div className='progress_bar_first_child' style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>{`${progress}%`}</span>
      </div>
    </div>
    )
}
 
export default Progress_bar;