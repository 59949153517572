import '../schoolSummary.scss';
import '../../../modal.scss';
import CurrencyInput from 'react-currency-input-field';
import React, { useState } from 'react';

function EditGoalModal(props) {

    const [tempGoal, setTempGoal] = useState(props.amount.replace("$","").replace(",",""))

    return (
        <div className='edit_modal_dialog modal_dialog'>
            <div className="flex-column-reverse">
                <h2 className="manage_badges_heading">Update School Goal</h2>
            </div>
            <div className="school_edit_modal_input_row flex-row">
                <div className="dollar_input_symbol"><p>$</p></div>
                <CurrencyInput
                    data-modal="true"
                    id="edit_goal_modal_input"
                    placeholder=""
                    defaultValue={tempGoal}
                    decimalsLimit={2}
                    onBlur={(event) => props.modalBlur(event, 'edit_goal_save_btn')}
                    onValueChange={(value) => {setTempGoal(value)}}
                    />
            </div>
            <div className="flex-row">
                <button id="edit_goal_close_btn" className='red_outline_button' 
                onClick={() => {props.showEditGoalModal(false)}} 
                data-modal="true"
                aria-label="Close edit goal modal">Close</button>
                <button id="edit_goal_save_btn" className='modal_save_button red_button' 
                onClick={() => {props.saveGoal(tempGoal)}} 
                data-modal="true"
                onBlur={(event) => props.modalBlur(event, 'edit_goal_modal_input')} 
                aria-label="save goal">Save</button>
            </div>
        </div>
    )
}

export default EditGoalModal;