import '../../modal.scss';
import './StandardModal.scss';

function StandardModal(props) {
    return (
        <div className='modal_dialog'>
            <div className="flex-column-reverse">
                <h2 className="modal_title">{props.title}</h2>
                <button id="standard_modal_dialog_close" onLoad={() => {this.focus()}} className="modal_dialog_close" 
                    onClick={() => {props.modalClose(false, null, props.refocusId)}} 
                    onBlur={(event) => props.modalBlur(event, "standard_modal_dialog_cancel" )} 
                    aria-label="Close message dialog" >
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="currentColor"
                            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                        />
                    </svg>
                </button>
            </div>
            <div className="standard_modal_message">{props.message}</div>
            <button id="standard_modal_dialog_cancel" className={"red_button standard_modal_close_button"} 
                    onClick={() => props.modalClose(false, null, props.refocusId)} aria-label="Close message dialog"
                    onBlur={(event) => props.modalBlur(event, "standard_modal_dialog_close" )} >Close</button>       
        </div>
    )
}

export default StandardModal;