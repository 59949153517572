import React from 'react';
import './ErrorPage.scss';
import '../../Theme.scss'

const ErrorPage = () => {
    return (
        <div className="page_container">
            <div className="page_content school_page_content">
                <h1 className="page_heading">Error</h1>
                <div>We're sorry the page you're looking for is unavailable. </div>
            </div>
        </div>
    )
};

export default ErrorPage;