import React, { useState } from 'react';
import './resendEmailModal.scss';
import { useUser } from '../../context/UserContext';
import { buildForm, isEmail, getApiBaseUrl } from '../../helpers/globalFunctions';
import { Oval } from 'react-loader-spinner';

const ResendEmailModal = (props) => {
    const { getUserJwt } = useUser()

    const [resendEmailName, setResendEmailName] = useState('');
    const [resendEmailAddress, setResendEmailAddress] = useState('')
    const [resendEmailSubmitted, setResendEmailSubmitted] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isResending, setIsResending] = useState(false)


    const clickResendEmailButton = async (id) => {
        setIsResending(true)
        setResendEmailSubmitted(true);
        setErrorMessage("")

        if (!resendEmailName || !resendEmailAddress || !isEmail(resendEmailAddress)) {
            setIsResending(false)
            return
        }

        let body_data = {
            name: resendEmailName,
            email: resendEmailAddress,
        }

        var formBody = buildForm(body_data)
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        let jwt = await getUserJwt();
        var bearer = "Bearer " + jwt;
        headers.append("Authorization", bearer);

        fetch(`${getApiBaseUrl()}/email/resendEmail/${id}`, {
            method: 'post',
            headers: headers,
            body: formBody,
        })
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.success) {
                    props.openResendEmailDialog(null, false)
                } else {
                    setIsResending(false)
                    setErrorMessage("There was an error sending the email. Please check the recipient's email address.")
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }

    return (
        <div className='modal_dialog resend_email_modal_dialog'>
            <div className="flex-column-reverse">
                <h2 className="modal_title small_heading">Resend Email</h2>
                <button id="resend_email_modal_dialog_close" onLoad={() => { this.focus() }} className="modal_dialog_close"
                    onClick={() => { props.openResendEmailDialog(null, false) }}
                    onBlur={(event) => props.modalBlur(event, "resend_email_modal_dialog_cancel")}
                    aria-label="Close" >
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="currentColor"
                            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                        />
                    </svg>
                </button>
            </div>
            <div className='resend_email_form_section'>
                <div className='resend_email_form_section_item'>
                    <label htmlFor="resend_email_name" aria-label='Name, Required'><span>*Name</span>
                        {(resendEmailSubmitted && (resendEmailName == '')) && (
                            <span className='small_error_text required_error_message'>Required Field</span>
                        )}
                    </label>
                    <input type='text' data-modal="true" id="resend_email_name" value={resendEmailName} onChange={e => setResendEmailName(e.target.value)} />

                </div>
                <div className='resend_email_form_section_item'>
                    <label htmlFor="resend_email_email" aria-label='Email, Required'><span>*Email</span>
                        {resendEmailSubmitted && (
                            <>
                                {
                                    (resendEmailAddress == '') && (
                                        <span className='small_error_text required_error_message'>Required Field</span>
                                    )
                                }
                                {

                                    (resendEmailAddress && !isEmail(resendEmailAddress)) && (
                                        <span className='small_error_text required_error_message'>Invalid Email</span>
                                    )
                                }
                            </>
                        )
                        }
                    </label>
                    <input type='text' data-modal="true" id="resend_email_email" value={resendEmailAddress} onChange={e => setResendEmailAddress(e.target.value)} />
                </div>
            </div>
            {errorMessage != "" && (
                <span role='alert' className='search_section_error_text'>{errorMessage}</span>
            )}
            <div className='resend_email_buttons'>
                {
                    isResending && (
                        <>
                        <div className='resend_email_modal_resending_text'>Resending...</div>
                        <Oval
                            visible={true}
                            height="15"
                            width="15"
                            strokeWidth="2"
                            color="#003399"
                            secondaryColor="#003399"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        </>
                    )
                }
                {
                    !isResending && (
                        <>
                            <button data-modal="true" className="red_button" onClick={() => clickResendEmailButton(props.id)}>Send</button>
                            <button id="resend_email_modal_dialog_cancel" className={"blue_text_button"}
                                onClick={() => props.openResendEmailDialog(null, false)} aria-label="Cancel"
                                onBlur={(event) => props.modalBlur(event, "resend_email_modal_dialog_close")} >Cancel</button>
                        </>
                    )
                }

            </div>
        </div>
    )
}

export default ResendEmailModal;