import React from "react";
import "./ProgressCircle.scss"

const ProgressCircle = ({ percentage, color }) => {
    const cleanPercentage = (percentage) => {
        const tooLow = !Number.isFinite(+percentage) || percentage < 0;
        const tooHigh = percentage > 100;
        return tooLow ? 0 : tooHigh ? 100 : +percentage;
    };    
    
    const pct = cleanPercentage(percentage);
    const fillCircle = pct === 100 ? "hidden" : "visible";
    let edgeRotation = 285;
    let edgeColor = color;
    let edgePct = pct - 6;
    let mainDelta = pct === 100 ? 0 : 2;
    let mainStyle = "butt";
    let edgeStyle = "round";
    if (pct < 7)
    {
        mainStyle = "round";
        edgeColor = "lightgrey";
        edgePct = 6;
        edgeRotation = 248;
        edgeStyle = "butt";
        if(pct === 2 || pct === 3 || pct === 4)
            mainDelta = 1;
    }
    if(pct === 0)
        color = "lightgrey";

    const Text = ({ percentage }) => {
        return (
            <text
            x="50%"
            y="50%"
            dominantBaseline="central"
            textAnchor="middle"
            fontSize={"1.5em"}
            >
            {percentage.toFixed(0)}%
            </text>
        );
    };

    const Circle = ({ color, pct, strokeLinecap }) => {
        const r = 70;
        const circ = 2 * Math.PI * r;
        const strokePct = ((100 - pct) * circ) / 100;
        
        return (
            <circle
            r={r}
            cx={100}
            cy={100}
            fill="transparent"
            stroke={strokePct !== circ ? color : ""} // remove color as 0% sets full circumference
            strokeWidth={"2rem"}
            strokeDasharray={circ}
            strokeDashoffset={pct ? strokePct : 0}
            strokeLinecap={strokeLinecap}
            ></circle>
        );
    };

    return (
        <svg viewBox="0 0 200 200">
        <g transform={`rotate(270 ${"100 100"})`}>
            <Circle color="lightgrey" strokeLinecap="butt" />
            <Circle color={color} pct={pct-mainDelta} strokeLinecap={mainStyle}/>            
        </g>
        <g transform={`rotate(${edgeRotation} ${"100 100"})`} visibility={fillCircle}>
            <Circle color={edgeColor} pct={edgePct} strokeLinecap={edgeStyle} />
        </g>
        <Text percentage={pct} />
        </svg>
    );
};

export default ProgressCircle;