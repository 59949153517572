import React, { createContext, useContext, useState } from "react";
import { getApiBaseUrl } from "../helpers/globalFunctions";
import { jwtDecode } from "jwt-decode";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null); //Initial user 
    const [appReady, setAppReady] = useState(false);
    const [invalidAccount, setInvalidAccount] = useState(false);

    const samlLogin = () => {
        var samlUrl = `${ getApiBaseUrl()}/saml/login`;
        window.location.replace(samlUrl)
    }

    const setWebSmt = async (event_id, company_id) => {
        let new_user = user
        new_user.event_id = event_id
        new_user.company_id = company_id
        setUser(new_user)
        window.ahaWebSMT.event_id = event_id;
        window.ahaWebSMT.company_id = company_id;
        window.ahaWebSMT.jwt = await getUserJwt()
        window.ahaWebSMT.permissions = user.permissions
        window.ahaWebSMT.first_name = user.first_name
        window.ahaWebSMT.last_name = user.last_name
 
        window.ahaWebSMT.getConfigs()
    }

    const getUserJwt = () => {
        let should_refresh = false

        if(user && user.jwt){
            let decodedToken = jwtDecode(user.jwt);
            let currentDate = new Date();
          
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
              should_refresh = true
            }else{
                return user.jwt
            }
        }else{
            should_refresh = true
        }

        if(should_refresh){
            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
    
            return fetch(`${getApiBaseUrl()}/saml/token`, {
                method: 'get',
                credentials: 'include',
                mode: 'cors',
                headers: headers,
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if(!appReady){
                    setAppReady(true);
                }
                if(responseJson.user){
                    responseJson.user['is_authenticated'] = true
                    setInvalidAccount(false)
                    setUser(responseJson.user)
                    return responseJson.user.jwt
                }else{
                    if(responseJson.statusCode === 401 && responseJson.message == 'Invalid Account'){
                        setInvalidAccount(true)
                    } else{
                        setInvalidAccount(false)
                    }
                }
            })
            .catch((error) => {
                setAppReady(true);
                console.error(error)
            });
        }
    }

    return (
        <UserContext.Provider value={{ user, appReady, invalidAccount, samlLogin, setWebSmt, getUserJwt}}>
            {[children]}
        </UserContext.Provider>
    )
};

export const useUser = () => useContext(UserContext);