import React from "react";
import { NavLink } from "react-router-dom";
import "./SideBarItem.scss"

const SideBarItem = (props) => {

    return (
        <NavLink to={props.route} className="sidebar_link">{props.name}</NavLink>
    );
};

export default SideBarItem;