import React, { useCallback, useEffect, useState } from 'react';
import './giveBackDirectContribution.scss';
import '../../Theme.scss'
import { useUser } from '../../context/UserContext';
import { buildForm, formatDate, getApiBaseUrl } from '../../helpers/globalFunctions';
import SortTableHeader from '../../components/SortTableHeader/SortTableHeader';
import TablePageSizeOptions from '../../components/TablePageSizeOptions/TablePageSizeOptions';
import GiveBackSideBar from '../../components/GiveBackSideBar/GiveBackSideBar';
import SimplePagination from '../../components/Pagination/SimplePagination';
import SelectColumnsModal from '../../modals/SelectColumnsModal/SelectColumnsModal';
import DownloadingModal from '../../modals/DownloadingModal/DownloadingModal';
import StandardModal from '../../modals/StandardModal/StandardModal';

const GiveBackDirectContribution = ({displayModal, closeModal, setModalContent, setShowSchoolName}) => {
    const { getUserJwt } = useUser()

    const [initialSearch, setInitialSearch] = useState(true)
    const [allowNoResults, setAllowNoResults] = useState(false)
    const [shouldSearch, setShouldSearch] = useState(true)
    const [hasFilter, setHasFilter] = useState(false)

    const [pageIndex, setPageIndex] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [records, setRecords] = useState([])

    const [selectedDynamicsId, setSelectedDynamicsId] = useState('')
    const [selectedOrderId, setSelectedOrderId] = useState('')
    const [selectedSchoolName, setSelectedSchoolName] = useState('')
    const [selectedCheckNumber, setSelectedCheckNumber] = useState('')
    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedEndDate, setSelectedEndDate] = useState('')
    const [selectedAmountMin, setSelectedAmountMin] = useState('')
    const [selectedAmountMax, setSelectedAmountMax] = useState('All')
    
    const [sortBy, setSortBy] = useState('school_id')
    const [displaySortBy, setDisplaySortBy] = useState('school_id')
    const [orderBy, setOrderBy] = useState('ASC')
    const [displayOrderBy, setDisplayOrderBy] = useState('ASC')

    const [dailyCashbackDisabled, setDailyCashbackDisabled] = useState(false)
    const [shouldUpdateDailyCashbackDisabled, setShouldUpdateDailyCashbackDisabled] = useState(false)

    const [allColumns, setAllColumns] = useState({
        dynamics_id: { id: 'dynamics_id', name: 'Dynamics Id', display: true },
        order_id: { id: 'order_id', name: 'Order Id', display: true },
        region: {id: 'region', name: 'Region', display: true},
        school_name: { id: 'school_name', name: 'School Name', display: true },
        coordinator_name: { id: 'coordinator_name', name: 'Coordinator Name', display: true },
        total_collected: { id: 'total_collected', name: 'Total Collected', display: true },
        check_total: { id: 'check_total', name: 'Check Total', display: true },
        check_number: { id: 'check_number', name: 'Check Number', display: true },
        date_received: { id: 'date_received', name: 'Date Received', display: true },
        date_to_boa: { id: 'date_to_boa', name: 'Date to BOA', display: true },
        date_processed: { id: 'date_processed', name: 'Date Processed', display: true },
        boa_status: { id: 'boa_status', name: 'BOA Status', display: true },
        boa_file_name: { id: 'boa_file_name', name: 'File Name', display: true },
        boa_file_content: { id: 'boa_file_content', name: 'BOA File', display: true },
    });

    const [downloadingInProgress, setDownloadingInProgress] = useState(false)
    const [downloadingPageIndex, setDownloadingPageIndex] = useState(null)
    const [csvData, setCsvData] = useState(null)
    const [csvDataHeaders, setCsvDataHeaders] = useState(null)
    
    useEffect(() => {
        setShowSchoolName(false)
    })

    useEffect(() => {
        if((selectedDynamicsId || selectedOrderId || selectedSchoolName || selectedCheckNumber || selectedStartDate
            || selectedEndDate || selectedAmountMin || selectedAmountMax ) && !initialSearch){
                setHasFilter(true)
            }else{ 
                setHasFilter(false)
            }
    }, [initialSearch,
        selectedDynamicsId,
        selectedOrderId,
        selectedSchoolName,
        selectedCheckNumber,
        selectedStartDate,
        selectedEndDate,
        selectedAmountMin,
        selectedAmountMax,])

    useEffect(() => {
        if(shouldSearch){
            async function fetchData() {
                setShouldSearch(false)
                let body_data = {
                    page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                    page_index: pageIndex,
                    dynamics_id: selectedDynamicsId,
                    school_name: selectedSchoolName,
                    order_id: selectedOrderId,
                    check_number: selectedCheckNumber,
                    start_date: selectedStartDate,
                    end_date: selectedEndDate,
                    amount_min: selectedAmountMin,
                    amount_max: selectedAmountMax,
                    sort_by: sortBy,
                    order_by: orderBy,
                }
        
                var formBody = buildForm(body_data)
                var headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                let jwt = await getUserJwt()
                var bearer = "Bearer " + jwt;
                headers.append("Authorization", bearer);
        
                fetch(`${getApiBaseUrl()}/order/adminReports/directContribution`, {
                    method: 'post',
                    headers: headers,
                    body: formBody,
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if(initialSearch){
                            setInitialSearch(false) 
                        }
                        if (responseJson.data) {
                            setDailyCashbackDisabled(responseJson.data.disabled_config)
                            setRecords(responseJson.data.records)
                            setPageCount(responseJson.data.pages_count)
                            setAllowNoResults(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    });
            }
            fetchData();
        }
        },[shouldSearch, getUserJwt, initialSearch, orderBy, pageIndex, pageSize, selectedOrderId,selectedCheckNumber, selectedAmountMax,
        selectedDynamicsId, selectedSchoolName, selectedAmountMin, selectedStartDate, selectedEndDate, sortBy]
    )

    const clickSortColumn = (col) => {
        if (col === sortBy) {
            let updated_order_by = orderBy === 'ASC' ? 'DESC' : 'ASC'
            setOrderBy(updated_order_by, setDisplayOrderBy(updated_order_by, setShouldSearch(true)))
        } else {
            setSortBy(col, setDisplaySortBy(col, setShouldSearch(true)))
        }
    }

    const handleEnterPressed = (event) => {
        if(event.keyCode === 13){
            event.preventDefault();
            event.target.click()
            document.activeElement.blur()
            setTimeout(() => {event.target.focus()},500)
        }
    }

    const modalBlur = (event, id) => {
        if(event.relatedTarget){
            if(event.relatedTarget.getAttribute('data-modal') !== 'true'){
                event.preventDefault();
                document.getElementById(id).focus();
            }
        }
    }

    const refocusElement = useCallback((id, index) => {
        setTimeout(async () => {
            let element = document.getElementById(id);
            if(index < 10){
                if(element && element !== document.activeElement){
                    element.focus();
                }else{
                setTimeout(()=>{refocusElement(id, index+1)}, 50)
                }
            }
        }, 250);
    }, [])

    const clickSearchButton = (col) => {
        setSortBy(displaySortBy, setOrderBy(displayOrderBy, setShouldSearch(true)))
    }

    const handleDynamicsIdInputChange = (e) => {
        const value = e.target.value;
        if (isValidSearch(value)) {
          setSelectedDynamicsId(value);
        }
    };

    const isValidSearch = (val) => {
        let regDynamicsSearch = /^[a-zA-Z0-9-]*$/;
        return regDynamicsSearch.test(val);
    }

    const clearSearchFilters = () => {
        setSelectedDynamicsId('')
        setSelectedSchoolName('')
        setSelectedOrderId('')
        setSelectedCheckNumber('')
        setSelectedStartDate('')
        setSelectedEndDate('')
        setSelectedAmountMin('')
        setSelectedAmountMax('All')
        setSortBy('school_id')
        setDisplaySortBy('school_id')
        setOrderBy('ASC')
        setDisplayOrderBy('ASC')
    }

    const clearSearch = async () => {
        await clearSearchFilters()
        setHasFilter(false, setShouldSearch(true))
    }

    const updatePageSize = (size) => {
        setPageSize(size, setPageIndex(0, setShouldSearch(true))  )
    }

    const updatePageIndex = (new_index) => {
        if(new_index === 'next'){
            new_index = pageIndex + 1
        }else if(new_index === 'prev'){
            new_index = pageIndex - 1
        }

        if(new_index >=0 && new_index < pageCount){
            setPageIndex(new_index, setShouldSearch(true))
        }
    }

    const showColumnModal = (display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<SelectColumnsModal
                modalBlur={modalBlur} modalClose={showColumnModal}
                columns={allColumns} updateColumnDisplay={updateColumnDisplay}
            />)
            displayModal()
            refocusElement('select_columns_modal_dialog_close',0)
        } else {
            setModalContent("")
            closeModal()
            refocusElement('direct_contribution_select_columns_button', 0);
        }
    }

    const updateColumnDisplay = (event, col) => {
        let new_column = allColumns[col]

        if(new_column){
            new_column.display = !new_column.display
            setAllColumns({
                ...allColumns,
                [col]: new_column
            })
        }
    }

    const downloadBoaFile = (rec, index) => {
        let file_name = rec.boa_file_name.split('.csv')[0]
        let file_content = rec.boa_file_content 

        downloadFile({
            data: file_content,
            fileName: `${file_name}.csv`,
            fileType: 'text/csv',
            report: false
        })

        refocusElement('download_boa_file_' + index)
        
    }

    const exportToCsvHeaders = useCallback(async () => {
        let headers = [];
        let selected_columns = [];

        Object.keys(allColumns).forEach(col => {
            let element = allColumns[col]
            if(element.display){
                headers.push(element.name)
                selected_columns.push(element.id)
            }
        });

        setCsvData([headers])
        setCsvDataHeaders(selected_columns)
        setDownloadingPageIndex(0)
    }, [allColumns])

    const showDownloadingModal = useCallback((display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<DownloadingModal
                modalBlur={modalBlur} modalClose={showDownloadingModal}
                downloadingInProgress={true}
            />)
            displayModal()
            refocusElement('downloading_modal_dialog_close',0)
            setDownloadingInProgress(true)
            exportToCsvHeaders()
        } else {
            setDownloadingInProgress(false)
            setDownloadingPageIndex(null)
            setCsvData(null)
            setCsvDataHeaders(null)
            setModalContent("")
            closeModal()
            refocusElement('direct_contribution_download_button', 0);
        }
    }, [closeModal, displayModal, exportToCsvHeaders, refocusElement, setModalContent])


    const downloadFile = useCallback(({ data, fileName, fileType, report }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        if(report){
            showDownloadingModal(false)
        }
    }, [showDownloadingModal])

    const showDownloadingError = useCallback(() => {
        setDownloadingInProgress(false)
        setDownloadingPageIndex(null)
        setCsvData(null)
        setCsvDataHeaders(null)

        setModalContent(<DownloadingModal
            modalBlur={modalBlur} modalClose={showDownloadingModal}
            downloadingInProgress={false}
        />)
    }, [setModalContent, showDownloadingModal])

    const escapeCsvField = (field) => {
        if (field === null || field === undefined) {
            return '';
        }
        if (typeof field !== 'string') {
            field = String(field);
        }
        if (field.includes(',') || field.includes('"') || field.includes('\n') || field.includes('\r') || field.startsWith(' ') || field.endsWith(' ')) {
            return `"${field.replace(/"/g, '""')}"`;
        }
        return field;
    }

    useEffect(() => {
        async function fetchData() {
            if(downloadingPageIndex !== null && csvData){
                if(downloadingInProgress){
                    let download_page_size = 500;
                    let body_data = {
                        page_size: download_page_size,
                        page_index: downloadingPageIndex,
                        dynamics_id: selectedDynamicsId,
                        school_name: selectedSchoolName,
                        order_id: selectedOrderId,
                        check_number: selectedCheckNumber,
                        start_date: selectedStartDate,
                        end_date: selectedEndDate,
                        amount_min: selectedAmountMin,
                        amount_max: selectedAmountMax,
                        sort_by: sortBy,
                        order_by: orderBy,
                    }

                    var formBody = buildForm(body_data)
                    var http_headers = new Headers();
                    http_headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                    let jwt = await getUserJwt()
                    var bearer = "Bearer " + jwt;
                    http_headers.append("Authorization", bearer);
            
                    fetch(`${getApiBaseUrl()}/order/adminReports/directContribution`, {
                        method: 'post',
                        headers: http_headers,
                        body: formBody,
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            let new_data = csvData
                            responseJson.data.records.forEach(element => {
                                let array = [];
                                csvDataHeaders.forEach(header => {
                                    array.push(escapeCsvField(element[header]))
                                });
                            
                                new_data.push(array)
                            });

                            setCsvData(new_data)

                            if(responseJson.data.records.length < download_page_size ){
                                let today = new Date();
                                let formattedDate = formatDate(today);
                    
                                downloadFile({
                                    data: new_data.map(row => row.join(',')).join('\n'),
                                    fileName: `directContribution${formattedDate}.csv`,
                                    fileType: 'text/csv',
                                    report: true
                                })
                            }else{
                                setDownloadingPageIndex(downloadingPageIndex + 1)
                            }
                        }else{
                            showDownloadingError()
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        showDownloadingError()
                    });
                }else{
                    showDownloadingError()
                }
            }
        }
        fetchData();
    }, [downloadingPageIndex, csvData, csvDataHeaders, downloadFile, downloadingInProgress, getUserJwt, orderBy, selectedOrderId, 
        selectedCheckNumber, selectedAmountMax, selectedDynamicsId, selectedSchoolName, selectedAmountMin,
        selectedStartDate, selectedEndDate, showDownloadingError, sortBy])

    
        useEffect(() => {
            if(shouldUpdateDailyCashbackDisabled){
                async function fetchData() {
                    setShouldUpdateDailyCashbackDisabled(false)
           
                        let body_data = {
                            config_name: 'daily_cashback_post_disabled',
                            config_value: 'false'
                        }
                
                        var formBody = buildForm(body_data)
                        var headers = new Headers();
                        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                        let jwt = await getUserJwt()
                        var bearer = "Bearer " + jwt;
                        headers.append("Authorization", bearer);
                
                        fetch(`${getApiBaseUrl()}/configuration/updateConfigAndHistory`, {
                            method: 'post',
                            headers: headers,
                            body: formBody,
                        })
                        .then((response) => {
                            return response.json()
                        })
                        .then((responseJson) => {
                            if(responseJson.success){
                                setDailyCashbackDisabled(false)
                                setShouldUpdateDailyCashbackDisabled(false)
                            }else{
                                showStandardModal(true, "Unable to restart. Please try again later.", "banner_action_button")
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            showStandardModal(true, "Unable to restart. Please try again later.", "banner_action_button")
                        });
                   
                }
                fetchData()
            }
        }, [shouldUpdateDailyCashbackDisabled, getUserJwt, StandardModal])

        const showStandardModal = useCallback((display, message, refocus_id) => {
            if (display) {
                document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
                setModalContent(<StandardModal
                    modalBlur={modalBlur} modalClose={showStandardModal} refocusId={refocus_id} message={message}
                />)
                displayModal()
                refocusElement('standard_modal_dialog_close',0)
            } else {
                closeModal()
                refocusElement(refocus_id, 0);
            }
        }, [closeModal, displayModal, refocusElement, setModalContent])

    return (
        <div className="page_container">
            <div className='menu_content'>
                <GiveBackSideBar></GiveBackSideBar>
            </div>
            <div className="page_content">
                <h1 className="page_heading">Direct Contribution Report</h1>
                {
                    dailyCashbackDisabled && 
                    <div className='general_banner'>
                        <div className='general_banner_message'>Warning - Threshold level has been reached pausing processing until reviewed.</div>
                        <button id="banner_action_button" className="red_button" onClick={() => setShouldUpdateDailyCashbackDisabled(true)}>Restart</button>
                    </div>
                }
                    
                <div className="section_border">
                    <div className='search_section_info_text'>Select your search criteria below.</div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="direct_contribution_dynamics_id">Dynamics ID</label>
                            <input type='text' min="0" step="1" id="direct_contribution_dynamics_id" value={selectedDynamicsId} onChange={handleDynamicsIdInputChange} />
                        </div>

                        <div className='search_section_item'>
                            <label htmlFor="direct_contribution_order_id">Order ID</label>
                            <input type='number' min="0" step="1" id="direct_contribution_order_id" value={selectedOrderId} onChange={e => setSelectedOrderId(e.target.value)} />
                        </div>

                        <div className='search_section_item'>
                            <label htmlFor="direct_contribution_school_name">School Name</label>
                            <input type='text' id="direct_contribution_school_name" value={selectedSchoolName} onChange={e => setSelectedSchoolName(e.target.value)} />
                        </div>

                        <div className='search_section_item'>
                            <label htmlFor="direct_contribution_check_number_name">Check Number</label>
                            <input type='number' min="0" step="1" id="direct_contribution_check_number_name" value={selectedCheckNumber} onChange={e => setSelectedCheckNumber(e.target.value)} />
                        </div>
                    </div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item_double'>
                            <div className='search_section_double_title'>Date Range</div>
                            <div className='search_section_double_items flex_section'>
                                <div className='search_section_double_item'>
                                    <label htmlFor="direct_contribution_start_date">Start Date</label>
                                    <input type="date" id="direct_contribution_start_date" value={selectedStartDate} onChange={e => setSelectedStartDate(e.target.value)} />
                                </div>

                                <div className='search_section_double_item'>
                                    <label htmlFor="direct_contribution_end_date">End Date</label>
                                    <input type='date' id="direct_contribution_end_date" value={selectedEndDate} onChange={e => setSelectedEndDate(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className='search_section_item_double'>
                            <div className='search_section_double_title'>Contribution Amount</div>
                            <div className='search_section_double_items flex_section'>
                                <div className='search_section_double_item'>
                                    <label htmlFor="direct_contribution_minimum">Minimum</label>
                                    <input type='number' min="0" step="1" id="direct_contribution_minimum" value={selectedAmountMin} onChange={e => setSelectedAmountMin(e.target.value)} />
                                </div>

                                <div className='search_section_double_item'>
                                    <label htmlFor="direct_contribution_maximum">Maximum</label>
                                    <input type='number' min="0" step="1" id="direct_contribution_maximum" value={selectedAmountMax} onChange={e => setSelectedAmountMax(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_sort_by">Sort By</label>
                            <select id="schools_search_sort_by" value={displaySortBy} onChange={e => setDisplaySortBy(e.target.value)}>
                                {
                                    Object.entries(allColumns).map((col, index) => ( 
                                        <option key={index} value={col[1].id} className={col[1].display ? "" : "hidden"}>{col[1].name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_order_by">Order By</label>
                            <select id="schools_search_order_by" value={displayOrderBy} onChange={e => setDisplayOrderBy(e.target.value)}>
                                <option key="ASC" value="ASC">Ascending</option>
                                <option key="DESC" value="DESC">Descending</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex_section search_section_row search_section_buttons_row search_section_buttons_row_right'>
                        <div className='search_section_search_buttons'>
                            <button className={"blue_text_button " + (hasFilter ? 'displayBlock' : 'displayHidden')} onClick={() => clearSearch()}>Clear Search</button>
                            <button className="red_button" onClick={() => clickSearchButton()}>Search</button>
                        </div>
                    </div>
                </div>
                <div className='flex_section search_section_table_heading_row'>
                    <div className='small_heading search_section_table_heading_label'>Direct Contribution Report Results</div>
                    <div>
                        <div className='search_section_table_buttons'>
                            <button id="direct_contribution_select_columns_button" tabIndex="0" data-modal="true" className="red_outline_button" onClick={() => showColumnModal(true)}>Select Columns</button>
                            <button id="direct_contribution_download_button" className="red_button" onClick={(event) => showDownloadingModal(true)}>Download</button>
                        </div>

                        <TablePageSizeOptions page_options={[10, 25, 50, 'All']} page_value={pageSize} page_action={updatePageSize} />
                    </div>
                </div>
                <div className="table_container">
                    <table>
                        <thead>
                            <tr>
                                {
                                   Object.entries(allColumns).map((col, index) => ( 
                                       <SortTableHeader key={index} column_label={col[1].name} column_id={col[1].id} sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!col[1].display} />
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                records.length > 0 ?
                                records.map((rec, index) => (
                                    <tr id={`part${index}`} key={index}>
                                        {
                                            Object.entries(allColumns).map((col, index) => ( 
                                               col[1].id === 'boa_file_content' && col[1].display ? 
                                               <td key={col[1].id}>
                                                {
                                                    rec['boa_file_content'] && 
                                                    <button id={"download_boa_file_"+index} className='blue_text_button' aria-label={"Download file " + (rec.boa_file_name)} onClick={() => downloadBoaFile(rec, index )}>Download</button>
                                                }
                                                  
                                                </td>
                                               : <DataTableCell key={col[1].id}  name={col[1].id} row={rec} columns={allColumns}></DataTableCell>
                                                
                                            ))
                                        }
                                    </tr>
                                ))
                                : <tr><td className='report_no_results' colSpan={100}>{allowNoResults ? 'No Results Found' : ''} </td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                {
                    pageCount > 1 && <SimplePagination pageCount={pageCount} pageIndex={pageIndex} clickAction={updatePageIndex}></SimplePagination>
                }
            </div>
        </div>
    )
};

function DataTableCell(props) {
    if (props.columns[props.name].display) {
        let displayValue = props.row[props.name];
        
        if (props.name === 'total_collected' || props.name === 'check_total') {
            displayValue = displayValue != null ? `$${displayValue}` : displayValue;
        }
        return (
            <td>{displayValue}</td>
        )
    } else {
        return <></>
    }
};

export default GiveBackDirectContribution;