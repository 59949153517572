import React from "react";
import SideBarItem from "../SideBarItem/SideBarItem";
import { useUser } from "../../context/UserContext";

import "./SchoolSideBar.scss"

const SchoolSideBar = ({ event_id, company_id }) => {
    const {user} = useUser()
    const summaryRoute = "/school/summary/"+event_id+"/"+company_id;
    const planRoute = "/school/plan/"+event_id+"/"+company_id;
    const manageInstantGiftsRoute = "/school/instantgifts/"+event_id+"/"+company_id;
    const staffRoute = "/school/staff/"+event_id+"/"+company_id;
    const reportsRoute = "/school/report/"+event_id+"/"+company_id;
    const emailsRoute = "/school/emails/"+event_id+"/"+company_id;
    const orderPostEventGiftsRoute = "/school/orderposteventgifts/"+event_id+"/"+company_id;
    const schoolGiveBackRoute = "/school/giveback/"+event_id+"/"+company_id;
    return (
        <div className="side_bar">
            { user.pages && user.pages['school/summary'] && <SideBarItem name="Summary" route={summaryRoute}></SideBarItem> }
            { user.pages && user.pages['school/plan'] && <SideBarItem name="Plan" route={planRoute}></SideBarItem> }
            { user.pages && user.pages['school/instantgifts'] && <SideBarItem name="Manage Instant Gifts" route={manageInstantGiftsRoute}></SideBarItem> }
            { user.pages && user.pages['school/staff'] && <SideBarItem name="District/School Staff" route={staffRoute}></SideBarItem> }
            { user.pages && user.pages['school/report'] &&  <SideBarItem name="Report" route={reportsRoute}></SideBarItem> }
            { user.pages && user.pages['school/emails'] && <SideBarItem name="Emails" route={emailsRoute}></SideBarItem> }
            { user.pages && user.pages['school/orderposteventgifts'] && <SideBarItem name="Order Post-Event Gifts" route={orderPostEventGiftsRoute}></SideBarItem> }
            { user.pages && user.pages['school/giveback'] && <SideBarItem name="School Give-Back" route={schoolGiveBackRoute} event_id={event_id} company_id={company_id}></SideBarItem> }
        </div>
    );
};

export default SchoolSideBar;