import React, { useCallback, useEffect, useState } from 'react';
import './participantActivity.scss';
import '../../Theme.scss'
import { useUser } from '../../context/UserContext';
import { buildForm, formatDate, getApiBaseUrl } from '../../helpers/globalFunctions';
import SortTableHeader from '../../components/SortTableHeader/SortTableHeader';
import TablePageSizeOptions from '../../components/TablePageSizeOptions/TablePageSizeOptions';
import ParticipantSideBar from '../../components/ParticipantSideBar/ParticipantSideBar';
import SimplePagination from '../../components/Pagination/SimplePagination';
import SelectColumnsModal from '../../modals/SelectColumnsModal/SelectColumnsModal';
import DownloadingModal from '../../modals/DownloadingModal/DownloadingModal';


const ParticipantActivity = ({ displayModal, closeModal, setModalContent, setShowSchoolName }) => {
    const { getUserJwt } = useUser()

    const [initialSearch, setInitialSearch] = useState(true)
    const [allowNoResults, setAllowNoResults] = useState(false)
    const [shouldSearch, setShouldSearch] = useState(true)
    const [hasFilter, setHasFilter] = useState(false)
    const [includeMysteryGift, setIncludeMysteryGift] = useState(false)

    const [pageIndex, setPageIndex] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [eventYears, setEventYears] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [regions, setRegions] = useState([])

    const [participants, setParticipants] = useState([])

    const [selectedEventYear, setSelectedEventYear] = useState('')
    const [selectedEventType, setSelectedEventType] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('All')
    const [selectedParticipantType, setSelectedParticipantType] = useState('All')
    const [selectedStudentName, setSelectedStudentName] = useState('')
    const [selectedStudentId, setSelectedStudentId] = useState('')
    const [selectedSchoolName, setSelectedSchoolName] = useState('')
    const [selectedCompanyId, setSelectedCompanyId] = useState('')
    const [selectedCompletedMission, setSelectedCompletedMission] = useState(false)
    const [selectedMysteryGift, setSelectedMysteryGift] = useState(false)
    const [sortBy, setSortBy] = useState('first_name')
    const [displaySortBy, setDisplaySortBy] = useState('first_name')
    const [orderBy, setOrderBy] = useState('ASC')
    const [displayOrderBy, setDisplayOrderBy] = useState('ASC')

    const [missionColumns, setMissionColumns] = useState({});

    const [allColumns, setAllColumns] = useState({
        first_name: { id: 'first_name', name: 'First Name', display: true },
        last_name: { id: 'last_name', name: 'Last Name', display: true },
        display_name: { id: 'display_name', name: 'Display Name', display: true },
        email: { id: 'email', name: 'Email', display: true },
        participation_type_name: { id: 'participation_type_name', name: 'Participant Type', display: true },
        grade: { id: 'grade', name: 'Grade', display: true },
        teacher: { id: 'teacher', name: 'Teacher', display: true },
        amount_raised: { id: 'amount_raised', name: 'Amount Raised', display: true },
        registration_formatted: { id: 'registration_formatted', name: 'Registration Date', display: false },
        completed_mission: { id: 'completed_mission', name: 'Completed Mission', display: false },
        num_missions: { id: 'num_missions', name: 'Number of Missions to be Completed', display: false },
        facebook_fundraiser: { id: 'facebook_fundraiser', name: 'Facebook Fundraiser Created', display: false },
        num_emails: { id: 'num_emails', name: 'Number of Emails Sent', display: false },
    });

    const [downloadingInProgress, setDownloadingInProgress] = useState(false)
    const [downloadingPageIndex, setDownloadingPageIndex] = useState(null)
    const [csvData, setCsvData] = useState(null)
    const [csvDataHeaders, setCsvDataHeaders] = useState(null)
    
    useEffect(() => {
        setShowSchoolName(false)
        if (!initialSearch) {
            setHasFilter(true)
        }
    }, [selectedEventYear, selectedEventType, selectedRegion, selectedParticipantType, selectedStudentName, selectedStudentId, selectedSchoolName, selectedCompanyId, selectedMysteryGift, selectedCompletedMission, sortBy, orderBy, initialSearch, setShowSchoolName])

    useEffect(() => {
        if(shouldSearch){
            async function fetchData() {
                setShouldSearch(false)
                let body_data = {
                    page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                    page_index: pageIndex,
                    event_type: selectedEventType,
                    event_year: selectedEventYear,
                    region: selectedRegion,
                    participant_type: selectedParticipantType,
                    student_name: selectedStudentName,
                    student_id: selectedStudentId,
                    school_name: selectedSchoolName,
                    company_id: selectedCompanyId,
                    rare_mystery_gift: selectedMysteryGift,
                    completed_mission: selectedCompletedMission,
                    sort_by: sortBy,
                    order_by: orderBy,
                }

                var formBody = buildForm(body_data)
                var headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                let jwt = await getUserJwt()
                var bearer = "Bearer " + jwt;
                headers.append("Authorization", bearer);

                fetch(`${getApiBaseUrl()}/student/adminReports/participantActivity`, {
                    method: 'post',
                    headers: headers,
                    body: formBody,
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            setIncludeMysteryGift(responseJson.data.rare_mystery_gift)
                            if(!responseJson.data.rare_mystery_gift && selectedMysteryGift){
                                setSelectedMysteryGift(false)
                            }
                            if (initialSearch) {
                                setEventYears(responseJson.data.event_years)
                                setEventTypes(responseJson.data.event_types)
                                setRegions(responseJson.data.regions)
                                setInitialSearch(false)
                            }
                            let missions = {}

                            responseJson.data.missions.forEach(m => {
                                missions[m.tag] = {
                                    id: m.tag,
                                    name: m.name,
                                    display: missionColumns[m.tag]?.display ? true : false
                                }
                            });
                            setMissionColumns(missions)
                            setParticipants(responseJson.data.participants)
                            setPageCount(responseJson.data.pages_count)
                            setAllowNoResults(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    });
                }
            fetchData()
        }
    }, [shouldSearch, getUserJwt, initialSearch, orderBy, pageIndex, pageSize,
        selectedCompanyId, selectedCompletedMission, selectedEventType, selectedEventYear, 
        selectedMysteryGift, selectedParticipantType, selectedRegion, selectedSchoolName,
        selectedStudentId, selectedStudentName, sortBy])

    const clickSortColumn = (col) => {
        if (col === sortBy) {
            let updated_order_by = orderBy === 'ASC' ? 'DESC' : 'ASC'
            setOrderBy(updated_order_by, setDisplayOrderBy(updated_order_by, setShouldSearch(true)))
        } else {
            setSortBy(col, setDisplaySortBy(col, setShouldSearch(true)))
        }
    }

    const handleEnterPressed = (event) => {
        if(event.keyCode === 13){
            event.preventDefault();
            event.target.click()
            document.activeElement.blur()
            setTimeout(() => {event.target.focus()},500)
        }
    }

    const modalBlur = (event, id) => {
        if(event.relatedTarget){
            if(event.relatedTarget.getAttribute('data-modal') !== 'true'){
                event.preventDefault();
                document.getElementById(id).focus();
            }
        }
    }

    const refocusElement = useCallback((id, index) => {
        setTimeout(async () => {
            let element = document.getElementById(id);
            if(index < 10){
                if(element && element !== document.activeElement){
                    element.focus();
                }else{
                setTimeout(()=>{refocusElement(id, index+1)}, 50)
                }
            }
        }, 250);
    }, [])

    const clickSearchButton = (col) => {
        setSortBy(displaySortBy, setOrderBy(displayOrderBy, setShouldSearch(true)))
    }

    const clearSearchFilters = () => {
        setSelectedEventYear(eventYears[0].name)
        setSelectedEventType(eventTypes[0].program_name)
        setSelectedRegion('All')
        setSelectedParticipantType("")
        setSelectedStudentName("")
        setSelectedStudentId('')
        setSelectedSchoolName('')
        setSelectedCompanyId('')
        setIncludeMysteryGift(false)
        setSelectedCompletedMission(false)
        setSortBy('school_name')
        setDisplaySortBy('school_name')
        setOrderBy('ASC')
        setDisplayOrderBy('ASC')
    }

    const clearSearch = async () => {
        await clearSearchFilters()
        setHasFilter(false, setShouldSearch(true))
    }

    const updatePageSize = (size) => {
        setPageSize(size, setPageIndex(0, setShouldSearch(true))  )
    }

    const updatePageIndex = (new_index) => {
        if(new_index === 'next'){
            new_index = pageIndex + 1
        }else if(new_index === 'prev'){
            new_index = pageIndex - 1
        }

        if(new_index >=0 && new_index < pageCount){
            setPageIndex(new_index, setShouldSearch(true))
        }
    }

    const showColumnModal = (display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            let columns = {...allColumns, ...missionColumns};
            setModalContent(<SelectColumnsModal
                modalBlur={modalBlur} modalClose={showColumnModal}
                columns={columns} updateColumnDisplay={updateColumnDisplay}
            />)
            displayModal()
            refocusElement('select_columns_modal_dialog_close',0)
        } else {
            setModalContent("")
            closeModal()
            refocusElement('participant_activity_select_columns_button', 0);
        }
    }

    const updateCheckboxValue = (item) => {
        if(item === 'completed_mission'){
            setSelectedCompletedMission(!selectedCompletedMission)
        }else if(item === 'rare_mystery_gift'){
            setSelectedMysteryGift(!selectedMysteryGift)
        }
    }

    const updateColumnDisplay = (event, col) => {
        let is_mission = false
        let new_column = allColumns[col]

        if(!new_column){
            is_mission = true;
            new_column = missionColumns[col]
        }

        if(new_column){
            new_column.display = !new_column.display
            if(is_mission){
                setMissionColumns({
                    ...missionColumns,
                    [col]: new_column
                })
            }else{
                setAllColumns({
                    ...allColumns,
                    [col]: new_column
                })
            }
        }
    }

    const exportToCsvHeaders = useCallback(async () => {
        let headers = [];
        let selected_columns = [];

        Object.keys(allColumns).forEach(col => {
            let element = allColumns[col]
            if(element.display){
                headers.push(element.name)
                selected_columns.push(element.id)
            }
        });

        Object.keys(missionColumns).forEach(col => {
            let element = missionColumns[col]
            if(element.display){
                headers.push(element.name)
                selected_columns.push(element.id)
            }
        });

        setCsvData([headers])
        setCsvDataHeaders(selected_columns)
        setDownloadingPageIndex(0)
    }, [allColumns, missionColumns])

    const showDownloadingModal = useCallback((display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<DownloadingModal
                modalBlur={modalBlur} modalClose={showDownloadingModal}
                downloadingInProgress={true}
            />)
            displayModal()
            refocusElement('downloading_modal_dialog_close',0)
            setDownloadingInProgress(true)
            exportToCsvHeaders()
        } else {
            setDownloadingInProgress(false)
            setDownloadingPageIndex(null)
            setCsvData(null)
            setCsvDataHeaders(null)
            setModalContent("")
            closeModal()
            refocusElement('participant_activity_download_button', 0);
        }
    }, [closeModal, displayModal, exportToCsvHeaders, refocusElement, setModalContent])


    const downloadFile = useCallback(({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        showDownloadingModal(false)
    }, [showDownloadingModal])

    const showDownloadingError = useCallback(() => {
        setDownloadingInProgress(false)
        setDownloadingPageIndex(null)
        setCsvData(null)
        setCsvDataHeaders(null)

        setModalContent(<DownloadingModal
            modalBlur={modalBlur} modalClose={showDownloadingModal}
            downloadingInProgress={false}
        />)
    }, [setModalContent, showDownloadingModal])

    const escapeCsvField = (field) => {
        if (field === null || field === undefined) {
            return '';
        }
        if (typeof field !== 'string') {
            field = String(field);
        }
        if (field.includes(',') || field.includes('"') || field.includes('\n') || field.includes('\r') || field.startsWith(' ') || field.endsWith(' ')) {
            return `"${field.replace(/"/g, '""')}"`;
        }
        return field;
    }

    useEffect(() => {
        async function fetchData() {
            if(downloadingPageIndex != null && csvData){
                if(downloadingInProgress){
                    let download_page_size = 500;
                    let body_data = {
                        page_size: download_page_size,
                        page_index: downloadingPageIndex,
                        event_type: selectedEventType,
                        event_year: selectedEventYear,
                        region: selectedRegion,
                        participant_type: selectedParticipantType,
                        student_name: selectedStudentName,
                        student_id: selectedStudentId,
                        school_name: selectedSchoolName,
                        company_id: selectedCompanyId,
                        rare_mystery_gift: selectedMysteryGift,
                        completed_mission: selectedCompletedMission,
                        sort_by: sortBy,
                        order_by: orderBy,
                    }

                    var formBody = buildForm(body_data)
                    var http_headers = new Headers();
                    http_headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                    let jwt = await getUserJwt()
                    var bearer = "Bearer " + jwt;
                    http_headers.append("Authorization", bearer);
            
                    fetch(`${getApiBaseUrl()}/student/adminReports/participantActivity`, {
                        method: 'post',
                        headers: http_headers,
                        body: formBody,
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            let new_data = csvData
                            responseJson.data.participants.forEach(element => {
                                let array = [];
                                csvDataHeaders.forEach(header => {
                                    array.push(escapeCsvField(element[header]))
                                });
                            
                                new_data.push(array)
                            });

                            setCsvData(new_data)

                            if(responseJson.data.participants.length < download_page_size ){
                                let today = new Date();
                                let formattedDate = formatDate(today);
                    
                                downloadFile({
                                    data: new_data.map(row => row.join(',')).join('\n'),
                                    fileName: `participantsActivity${formattedDate}.csv`,
                                    fileType: 'text/csv',
                                })
                            }else{
                                setDownloadingPageIndex(downloadingPageIndex + 1)
                            }
                        }else{
                            showDownloadingError()
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        showDownloadingError()
                    });
                }else{
                    showDownloadingError()
                }
            }
        }
        fetchData()
    }, [downloadingPageIndex, csvData, csvDataHeaders, downloadFile, downloadingInProgress, getUserJwt, 
        orderBy, selectedCompanyId, selectedCompletedMission, selectedEventType, selectedEventYear, 
        selectedMysteryGift, selectedParticipantType, selectedRegion, selectedSchoolName, 
        selectedStudentId, selectedStudentName, showDownloadingError, sortBy])

    return (
        <div className="page_container">
            <div className='menu_content'>
                <ParticipantSideBar></ParticipantSideBar>
            </div>
            <div className="page_content">
                <h1 className="page_heading">Participant Activity</h1>
                <div className="section_border">
                    <div className='search_section_info_text'>Select your search criteria below to find participants.</div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="participants_search_event_type">*Event Type</label>
                            <select id="participants_search_event_type" aria-label="Event Type Required" 
                            value={selectedEventType} onChange={e => setSelectedEventType(e.target.value)}>
                                {
                                    eventTypes.map((option, index) => (
                                        <option key={option.program_name} value={option.program_name}>{option.program_name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="participants_search_event_year">*Event Year</label>
                            <select id="participants_search_event_year" aria-label="Event Year Required" 
                                value={selectedEventYear} onChange={e => setSelectedEventYear(e.target.value)}>
                                {
                                    eventYears.map((option, index) => (
                                        <option key={option.name} value={option.name}>{option.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="participants_search_region">*Region</label>
                            <select id="participants_search_region" aria-label="Region Required" 
                                value={selectedRegion} onChange={e => setSelectedRegion(e.target.value)}>
                                <option key="All" value="All">All</option>
                                {
                                    regions.map((option, index) => (
                                        <option key={option.name} value={option.name}>{option.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="participants_search_participant_type">Participant Type</label>
                            <select id="participants_search_participant_type" value={selectedParticipantType} onChange={e => setSelectedParticipantType(e.target.value)}>
                                <option key="All" value="All">All</option>
                                <option key="Student/Parent" value="Student/Parent">Student/Parent</option>
                                <option key="District/School Employee" value="District/School Employee">District/School Employee</option>
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="participants_search_student_name">Student Name</label>
                            <input type='text' id="participants_search_student_name" value={selectedStudentName} onChange={e => setSelectedStudentName(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="participants_search_student_id">Student Constituent ID</label>
                            <input type='number' min="0" step="1" id="participants_search_student_id" value={selectedStudentId} onChange={e => setSelectedStudentId(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="participants_search_school_name">School Name</label>
                            <input type='text' id="participants_search_school_name" value={selectedSchoolName} onChange={e => setSelectedSchoolName(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="participants_search_company_id">Company ID</label>
                            <input type='number' min="0" step="1" id="participants_search_company_id" value={selectedCompanyId} onChange={e => setSelectedCompanyId(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <div className="select_columns_checkbox_item">
                                <label className="checkbox_container">
                                    <input type="checkbox" 
                                         onClick={(event) => updateCheckboxValue('completed_mission')}
                                        aria-labelledby={"participant_activity_checkbox_label_completed_mission"} />
                                    <span className="checkbox_checkmark"></span>
                                    <div id={"participant_activity_checkbox_label_completed_mission"} className="checkbox_label_text">Completed Finn's Mission</div>
                                </label>
                            </div>
                        </div>
                        <div className='search_section_item'>
                            {
                                includeMysteryGift && 
                                <div className="select_columns_checkbox_item">
                                    <label className="checkbox_container">
                                        <input type="checkbox" 
                                            onClick={(event) => updateCheckboxValue('rare_mystery_gift')}
                                            aria-labelledby={"participant_activity_checkbox_label_mystery_gift"} />
                                        <span className="checkbox_checkmark"></span>
                                        <div id={"participant_activity_checkbox_label_mystery_gift"} className="checkbox_label_text">Rare Mystery Gift</div>
                                    </label>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='flex_section search_section_row search_section_buttons_row'>
                        <div className="search_section_error_text">*Required search criteria</div>
                        <div className='search_section_search_buttons'>
                            <button className={"blue_text_button " + (hasFilter ? 'displayBlock' : 'displayHidden')} onClick={() => clearSearch()}>Clear Search</button>
                            <button className="red_button" onClick={() => clickSearchButton()}>Search</button>
                        </div>
                    </div>
                </div>
                <div className='flex_section search_section_table_heading_row'>
                    <h2 className='page_heading search_section_table_heading_label'>Participant Activity Results</h2>
                    <div>
                        <div className='search_section_table_buttons'>
                            <button id="participant_activity_select_columns_button" tabIndex="0" data-modal="true" className="red_outline_button" onClick={() => showColumnModal(true)}>Select Columns</button>
                            <button id="participant_activity_download_button" className="red_button" onClick={(event) => showDownloadingModal(true)}>Download</button>
                        </div>

                        <TablePageSizeOptions page_options={[10, 25, 50, 'All']} page_value={pageSize} page_action={updatePageSize} />
                    </div>
                </div>
                <div className="table_container">
                    <table>
                        <thead>
                            <tr>
                            {
                                   Object.entries(allColumns).map((col, index) => ( 
                                       <SortTableHeader key={index} column_label={col[1].name} column_id={col[1].id} sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!col[1].display} />
                                    ))
                                }
                                {
                                   Object.entries(missionColumns).map((col, index) => ( 
                                       <SortTableHeader key={index} column_label={col[1].name} column_id={col[1].id} sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!col[1].display} />
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                participants.length > 0 ?
                                participants.map((part, index) => (
                                    <tr id={`part${index}`} key={index}>
                                        {
                                            Object.entries(allColumns).map((col, index) => ( 
                                                <DataTableCell key={col[1].id}  name={col[1].id} row={part} columns={allColumns}></DataTableCell>
                                            ))
                                        }
                                        {
                                            Object.entries(missionColumns).map((col, index) => ( 
                                                <DataTableCell key={col[1].id}  name={col[1].id} row={part} columns={missionColumns}></DataTableCell>
                                            ))
                                        }
                                    </tr>
                                ))
                                : <tr><td className='report_no_results' colSpan={100}>{allowNoResults ? 'No Results Found' : ''} </td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                {
                    pageCount > 1 && <SimplePagination pageCount={pageCount} pageIndex={pageIndex} clickAction={updatePageIndex}></SimplePagination>
                }
            </div>
        </div>
    )
};

function DataTableCell(props) {
    if (props.columns[props.name].display) {
        return (
            <td>{props.row[props.name]}</td>
        )
    } else {
        return <></>
    }
}

export default ParticipantActivity;