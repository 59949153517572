import React from "react";
import SideBarItem from "../SideBarItem/SideBarItem";
import { useUser } from "../../context/UserContext";

import "./ManageSideBar.scss"

const ManageSideBar = () => {
    const {user} = useUser()

    return (
        <div className="side_bar">
            { user.pages && user.pages['manage/emails'] && <SideBarItem name="Emails" route="/manage/emails"></SideBarItem> }
            { user.pages && user.pages['manage/globalmessage'] && <SideBarItem name="Global Message" route="/manage/globalmessage"></SideBarItem> }
            { user.pages && user.pages['manage/datamismatch'] && <SideBarItem name="Data Mismatch" route="/manage/datamismatch"></SideBarItem> }
        </div>
    );
};

export default ManageSideBar;