import './App.scss';
import './Theme.scss';
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import Login from './pages/login/login';
import School from './pages/school/school';
import Navbar from './components/Navbar/Navbar';
import Participants from './pages/participantBadges/participantBadges';
import ParticipantActivity from './pages/participantActivity/participantActivity';
import GiveBackDirectContribution from './pages/giveBackDirectContribution/giveBackDirectContribution';
import GiveBackUsGames from './pages/giveBackUsGames/giveBackUsGames';
import ManageEmails from './pages/manageEmails/manageEmails';
import ManageDataMismatch from './pages/manageDataMismatch/manageDataMismatch';
import ManageGlobalMessage from './pages/manageGlobalMessage/manageGlobalMessage';
import SchoolSummary from './pages/schoolSummary/schoolSummary';
import SchoolPlan from './pages/schoolPlan/schoolPlan';
import SchoolInstantGifts from './pages/schoolInstantGifts/schoolInstantGifts';
import SchoolStaff from './pages/schoolStaff/schoolStaff';
import SchoolReport from './pages/schoolReport/schoolReport';
import SchoolEmails from './pages/schoolEmails/schoolEmails';
import SchoolOrderPostEventGifts from './pages/schoolOrderPostEventGifts/schoolOrderPostEventGifts';
import SchoolGiveBack from './pages/schoolGiveBack/schoolGiveBack';
import Logout from './pages/logout/logout';
import { useUser } from './context/UserContext';
import useValidateUser from './hooks/validateUser';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Logo from "./images/AHA_AHC_LOGOS_CMYK_CROPPED.png"
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_ACCOUNT
}

TagManager.initialize(tagManagerArgs);


function App() {
	useValidateUser();
	const logo_image = Logo;
	const {user, appReady} = useUser()

	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState('');
	const [schoolName, setSchoolName] = useState('');
	const [showSchoolName, setShowSchoolName] = useState(false);
	 

	let fallback_route = ''
	if(user && user.pages){
		fallback_route = user.pages['fallback_route']
	}
	
	return (
		appReady && <div className="main_layout">
		  	<BrowserRouter>
				<Navbar logo_image={logo_image} />
				<div id="modal_background" className={`${showModal ? "visible" : "hidden"}`}>{modalContent}</div>
				{ showSchoolName && <h1 id="school_title">School: {schoolName}</h1>}
				{ user && user.jwt 
					? (
						<Routes>
							{ user.pages && user.pages['school'] && <Route path="/school" element={<School displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent} setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['participants/badges'] && <Route path="/participants/badges" element={<Participants displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent}  setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['participants/activity'] && <Route path="/participants/activity" element={<ParticipantActivity displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent} setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['giveback/directcontribution'] && <Route path="/giveback/directcontribution" element={<GiveBackDirectContribution   displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent} setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['giveback/usgames'] && <Route path="/giveback/usgames" element={<GiveBackUsGames setShowSchoolName={setShowSchoolName} displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent}/>} /> }
							{ user.pages && user.pages['manage/emails'] && <Route path="/manage/emails" element={<ManageEmails setShowSchoolName={setShowSchoolName} displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent}/>} /> }
							{ user.pages && user.pages['manage/datamismatch'] && <Route path="/manage/datamismatch" element={<ManageDataMismatch displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent} setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['manage/globalmessage'] && <Route path="/manage/globalmessage" element={<ManageGlobalMessage displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent}  setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['school/summary'] && <Route path="/school/summary/:event_id/:company_id" element={<SchoolSummary displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent} setSchoolName={setSchoolName} setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['school/plan'] && <Route path="/school/plan/:event_id/:company_id" element={<SchoolPlan setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['school/instantgifts'] && <Route path="/school/instantgifts/:event_id/:company_id" element={<SchoolInstantGifts setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['school/staff'] && <Route path="/school/staff/:event_id/:company_id" element={<SchoolStaff setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['school/report'] && <Route path="/school/report/:event_id/:company_id" element={<SchoolReport setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['school/emails'] && <Route path="/school/emails/:event_id/:company_id" element={<SchoolEmails setShowSchoolName={setShowSchoolName} displayModal={() => setShowModal(true)} closeModal={() => setShowModal(false)} setModalContent={setModalContent}/>} /> }
							{ user.pages && user.pages['school/orderposteventgifts'] && <Route path="/school/orderposteventgifts/:event_id/:company_id" element={<SchoolOrderPostEventGifts setShowSchoolName={setShowSchoolName}/>} /> }
							{ user.pages && user.pages['school/giveback'] && <Route path="/school/giveback/:event_id/:company_id" element={<SchoolGiveBack setShowSchoolName={setShowSchoolName} />} /> }
							<Route path="/logout" element={<Logout />} />
							<Route path="/error" element={<ErrorPage />} />
							{
								fallback_route && user.pages[fallback_route] ?
								<Route path="/*" element={<Navigate replace to={"/"+fallback_route} />} />		
								: <Route path="/*" element={<Navigate replace to="/error" />} />		
							}		
						</Routes>
					)
					: (
						<Routes>
							<Route path="/login" element={<Login/>} />
							<Route path="*" element={<Navigate replace to="/login" />} />							
						</Routes>
					)
				}
		  	</BrowserRouter>
		</div>
	);
}

export default App;
