import React, { useEffect } from 'react';
import './schoolPlan.scss';
import SchoolSideBar from '../../components/SchoolSideBar/SchoolSideBar';
import { useParams } from 'react-router-dom';
import { useUser } from '../../context/UserContext';

const SchoolPlan = ({setShowSchoolName}) => {
    const { event_id } = useParams();
    const { company_id } = useParams();
    const { setWebSmt } = useUser()
            
    useEffect(() => {
        setShowSchoolName(true)
        if(window.ahaWebSMT?.status === 'Ready' 
            && window.ahaWebSMT.event_id === event_id  
            && window.ahaWebSMT.company_id === company_id
        ){
            window.ahaWebSMT.schoolPage.setup()
        }else{
            if(event_id && company_id){
                setWebSmt(event_id, company_id)
                window.ahaWebSMT.schoolPage.setup()
            }
        }
    })

    return (
        <div className="page_container">
            <div className='menu_content'>
                <SchoolSideBar event_id={event_id} company_id={company_id}></SchoolSideBar>
            </div>
            <div className="page_content">
                <div id="nclvs_smt_school" event_id={event_id} company_id={company_id}></div>
            </div>
        </div>
    )
    
};

export default SchoolPlan;